import { Metadata, OutputCollectionState } from '@uploadcare/blocks'
import {
  FileUploaderInline,
  type UploadCtxProvider,
} from '@uploadcare/react-uploader'
import '@uploadcare/react-uploader/core.css'
import React, { memo, useCallback, useEffect } from 'react'

import {
  fileTypeMimes,
  fileTypeMimeSubtypes,
  UPLOADER_ACCEPTED_MIME_TYPES,
  UPLOADER_ALLOWED_FORMATS,
  UPLOADER_ALLOWED_SOURCES_LIST,
  UPLOADER_MAX_UPLOAD_SIZE_BYTES,
  UPLOADER_MAX_UPLOAD_SIZE_MB,
} from '@/constants'
import { cnConcat } from '@/utils'
import { DocumentFileTypeExtension } from '@/models/enums'

import {
  UploadedFileEntry,
  commonLocaleDefinitionOverride,
  injectValidationsTextInDropArea,
  removeValidationsTextFromDropArea,
} from './common'
import useUploaderCtxProvider from './useUploaderCtxProvider'

export interface UploadcareFileUploaderInlineProps {
  isDisabled?: boolean
  contextProviderRef?: React.RefObject<InstanceType<UploadCtxProvider>>
  onFilesUploadedSuccess(files: UploadedFileEntry[]): void
  onFilesUploadedFailed(count: number): void
  hideNativeUploadListToolbar?: boolean
  onUploading?(): void
  onFinished?(): void
  onDone?(): void
  metadata?: Metadata
  multiple?: boolean
  allowedFormats?: DocumentFileTypeExtension[]
  onFileAdded?(id: string): void
  onFileRemoved?(id: string): void
  onActivityChange?(event: string): void
  onInvalidMimeTypeUpload?(invalidUploads: UploadedFileEntry[]): void
}
const getUploadedEntriesWithInvalidMimeTypes = (
  uploadedFiles: UploadedFileEntry[],
  allowedFormats: DocumentFileTypeExtension[],
) => {
  const mimeTypes = allowedFormats.map(format => fileTypeMimes[format])
  const mimeSubtypes = allowedFormats.map(
    format => fileTypeMimeSubtypes[format],
  )

  return uploadedFiles.filter(
    file =>
      (file.fileInfo.contentInfo?.mime?.mime &&
        !mimeTypes.includes(file.fileInfo.contentInfo?.mime?.mime)) ||
      (file.fileInfo.contentInfo?.mime?.subtype &&
        !mimeSubtypes.includes(file.fileInfo.contentInfo?.mime?.subtype)),
  )
}

const UploadcareFileUploaderInline = memo(
  ({
    isDisabled = false,
    contextProviderRef,
    onUploading,
    onFinished,
    onDone,
    metadata,
    onFilesUploadedFailed,
    onFilesUploadedSuccess,
    multiple = true,
    allowedFormats = UPLOADER_ALLOWED_FORMATS,
    hideNativeUploadListToolbar = false,
    onFileAdded,
    onFileRemoved,
    onActivityChange,
    onInvalidMimeTypeUpload,
  }: UploadcareFileUploaderInlineProps) => {
    const { ctxProviderRef, resetUploaderState, uploadedFiles } =
      useUploaderCtxProvider({
        ref: contextProviderRef,
      })

    const onChangeHandler = useCallback(
      async (event: OutputCollectionState) => {
        const { status, successCount, successEntries, failedCount } = event

        if (status === 'success' || status === 'failed') {
          if (successCount) {
            if (!successEntries.length) return

            const invalidUploadedEntries =
              getUploadedEntriesWithInvalidMimeTypes(
                successEntries,
                allowedFormats,
              )
            const filteredSuccessEntries = successEntries.filter(
              entry =>
                !invalidUploadedEntries.some(
                  invalidEntry =>
                    invalidEntry.fileInfo.uuid === entry.fileInfo.uuid,
                ),
            )

            let filesToAdd = filteredSuccessEntries

            const successEntriesUuids = filteredSuccessEntries.map(
              entry => entry.fileInfo.uuid,
            )

            if (uploadedFiles.current?.length > 0) {
              filesToAdd = successEntries.filter(
                entry => !uploadedFiles.current.includes(entry.fileInfo.uuid),
              )

              uploadedFiles.current = Array.from(
                new Set([...uploadedFiles.current, ...successEntriesUuids]),
              )
            } else {
              uploadedFiles.current = successEntriesUuids
            }
            onInvalidMimeTypeUpload?.(invalidUploadedEntries)
            onFilesUploadedSuccess(filesToAdd)
          }

          if (failedCount) onFilesUploadedFailed(failedCount)

          onFinished?.()
        } else if (status === 'uploading') {
          onUploading?.()
        }
      },
      [
        onFilesUploadedFailed,
        onFilesUploadedSuccess,
        onFinished,
        onUploading,
        uploadedFiles,
        allowedFormats,
        onInvalidMimeTypeUpload,
      ],
    )

    const onFileAddedHandler = useCallback(
      (event: { internalId: string }) => {
        onFileAdded?.(event.internalId)
      },
      [onFileAdded],
    )

    const onFileRemovedHandler = useCallback(
      (event: { internalId: string }) => {
        onFileRemoved?.(event.internalId)
      },
      [onFileRemoved],
    )

    const onActivityChangeHandler = useCallback(
      (event: { activity: unknown }) => {
        onActivityChange?.(event.activity as string)
      },
      [onActivityChange],
    )

    useEffect(() => {
      injectValidationsTextInDropArea(
        allowedFormats.join(', '),
        UPLOADER_MAX_UPLOAD_SIZE_MB.toString(),
      )

      return () => removeValidationsTextFromDropArea()
    }, [allowedFormats])

    const acceptedTypes = UPLOADER_ACCEPTED_MIME_TYPES(allowedFormats)

    return (
      <div className="space-y-3">
        <FileUploaderInline
          removeCopyright
          confirmUpload
          checkForUrlDuplicates
          multiple={multiple}
          store={false}
          imgOnly={false}
          className={cnConcat(
            'file-uploader-wrapper',
            hideNativeUploadListToolbar && 'uploader-hide-native-toolbar',
            isDisabled && 'pointer-events-none opacity-disabled',
          )}
          localeDefinitionOverride={commonLocaleDefinitionOverride}
          pubkey={process.env.NEXT_PUBLIC_UPLOAD_CARE_PUBLIC_KEY}
          apiRef={ctxProviderRef}
          sourceList={UPLOADER_ALLOWED_SOURCES_LIST}
          maxLocalFileSizeBytes={UPLOADER_MAX_UPLOAD_SIZE_BYTES}
          accept={acceptedTypes}
          externalSourcesPreferredTypes={acceptedTypes}
          metadata={metadata}
          onDoneClick={onDone}
          onChange={onChangeHandler}
          onModalClose={resetUploaderState}
          onFileAdded={onFileAddedHandler}
          onFileRemoved={onFileRemovedHandler}
          onActivityChange={onActivityChangeHandler}
        />
      </div>
    )
  },
)

UploadcareFileUploaderInline.displayName = 'UploadcareFileUploaderInline'

export default UploadcareFileUploaderInline

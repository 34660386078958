/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react'

import { Icon } from '@iconify/react'
import { CircularProgress } from '@heroui/progress'

import { UIButton, UIModal } from '@/components/ui'
import { PROMPT_TIMEOUT_MS } from '@/constants'
import { useIdleTimer } from '@/hooks'
import { supabaseComponentClient } from '@/plugins/supabase'

const getFormattedTimerValue = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(remainingSeconds).padStart(2, '0')

  return `${formattedMinutes}:${formattedSeconds}`
}

export const IdleTimerModal = () => {
  const [open, setOpen] = useState(false)

  const [secondsBeforeSignOut, setSecondsBeforeSignOut] = useState(0)

  const signOut = () => supabaseComponentClient.auth.signOut()

  const onPrompt = () => {
    setOpen(true)
    setSecondsBeforeSignOut(PROMPT_TIMEOUT_MS / 1000)
  }

  const onIdle = () => {
    setOpen(false)
    setSecondsBeforeSignOut(0)
    signOut()
  }

  const onActive = () => {
    setOpen(false)
    setSecondsBeforeSignOut(0)
  }

  const { reset, isPrompted, getRemainingTime } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
  })

  const handleStillHere = () => {
    setOpen(false)
    reset()
  }

  const remainingSecondsPercentage =
    secondsBeforeSignOut > 0
      ? Math.ceil((secondsBeforeSignOut * 100) / (PROMPT_TIMEOUT_MS / 1000))
      : 0

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setSecondsBeforeSignOut(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <UIModal
      hideCloseButton
      placement="center"
      isOpen={open}
      onClose={() => setOpen(false)}
      isDismissable={false}
      className="w-full max-w-sm"
      title={<span className="w-full text-center">Still with us?</span>}
      headerClassName="text-2xl"
      footer={
        <div className="flex w-full items-center gap-4">
          <div className="flex-1">
            <UIButton fullWidth size="sm" variant="ghost" onClick={signOut}>
              Log Out
            </UIButton>
          </div>
          <div className="flex-1">
            <UIButton
              fullWidth
              size="sm"
              color="primary"
              onClick={handleStillHere}
              startContent={
                <Icon icon="solar:hand-shake-bold" className="h-5 w-5" />
              }
            >
              I'm Still Here
            </UIButton>
          </div>
        </div>
      }
    >
      <p className="text-center text-medium font-medium text-default-500">
        To ensure the safety of your data, we log you out automatically when you
        have not been active for a certain period of time.
      </p>
      <div className="mx-auto">
        <CircularProgress
          classNames={{
            svg: 'w-36 h-36 drop-shadow-md',
            indicator:
              remainingSecondsPercentage < 50
                ? 'stroke-red-500'
                : 'stroke-warning-500',
            track:
              remainingSecondsPercentage < 50
                ? 'stroke-red-500/10'
                : 'stroke-warning-500/10',
            value: 'text-2xl font-semibold',
          }}
          value={remainingSecondsPercentage}
          valueLabel={getFormattedTimerValue(secondsBeforeSignOut)}
          strokeWidth={3}
          showValueLabel={true}
        />
      </div>
      <p className="text-center text-small text-default-400">
        You will be logged out soon
      </p>
    </UIModal>
  )
}

import { Chip } from '@heroui/chip'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
} from '@heroui/dropdown'
import { User } from '@heroui/user'
import { useRouter } from 'next/router'
import React, { memo, useCallback, useMemo } from 'react'

import { useAuthContext, useLogOut } from '@/hooks'
import { useUser } from '@/hooks/queries/useUser'
import { useSelectedAccount } from '@/hooks/useSelectedAccount'
import { SidebarChildrenProps } from '@/models/components'
import { getInitials } from '@/utils'
import { LinkSquareIcon } from '@/components/ui'

import { SidebarThemeSelector } from './ThemeSelector'

const FREE_PLAN_NAME = 'Free'

const UNLIMITED_CREDITS = 'Unlimited'

const getRemainingCredits = (value: number | null | undefined) =>
  value === null || value === undefined
    ? UNLIMITED_CREDITS
    : value.toLocaleString()

export const SidebarUser = memo(({ isCompact }: SidebarChildrenProps) => {
  const user = useUser()

  const router = useRouter()

  const { userMetadata } = useAuthContext()

  const selectedAccount = useSelectedAccount()

  const logOut = useLogOut()

  const userFullName = useMemo(() => {
    return `${user.data?.firstName} ${user.data?.lastName}`
  }, [user.data?.firstName, user.data?.lastName])

  const currentPlan = useMemo(() => {
    return selectedAccount?.products.length === 0
      ? FREE_PLAN_NAME
      : selectedAccount?.products[0].name
  }, [selectedAccount?.products])

  const navigateToSettings = useCallback(
    () => router.push('/settings'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return (
    <div className="flex items-center gap-3 px-1.5">
      <Dropdown showArrow placement="right-end">
        <DropdownTrigger>
          <User
            as="button"
            avatarProps={{
              isBordered: true,
              showFallback: true,
              src: userMetadata?.avatar_url,
              getInitials,
            }}
            className="ui w-full justify-start transition-transform gap-3.5"
            classNames={{
              wrapper: isCompact ? 'hidden' : '',
              name: 'truncate text-default-600',
              description: 'truncate text-default-400',
            }}
            description={user.data?.email}
            name={userFullName}
          />
        </DropdownTrigger>
        <DropdownMenu aria-label="Account Details" disabledKeys={['profile']}>
          <DropdownSection
            showDivider
            aria-label="Profile"
            className={!isCompact ? 'hidden' : undefined}
          >
            <DropdownItem
              key="profile"
              isReadOnly
              className="h-14 gap-2 opacity-100"
              textValue="Signed in as"
            >
              <div className="flex max-w-full flex-col">
                <p className="mb-1 text-tiny font-light">Signed in as</p>
                <div className="flex flex-col">
                  <p className="truncate text-small font-medium text-default-600">
                    {userFullName}
                  </p>
                  <p className="truncate text-tiny text-default-400">
                    {user.data?.email}
                  </p>
                </div>
              </div>
            </DropdownItem>
          </DropdownSection>

          <DropdownSection showDivider aria-label="Plan and Details">
            <DropdownItem
              key="plan"
              isReadOnly
              className="cursor-default data-[hover=true]:bg-transparent"
              textValue="Current Plan"
              endContent={
                <Chip size="sm" variant="flat">
                  {currentPlan}
                </Chip>
              }
            >
              Current Plan
            </DropdownItem>
            <DropdownItem
              key="plan-status"
              isReadOnly
              className="h-auto cursor-default data-[hover=true]:bg-transparent"
              textValue="Available Uploads"
            >
              <div className="flex flex-col gap-1">
                <div className="rounded-small bg-default-100 px-2 py-1 group-data-[hover=true]:bg-default-200">
                  <span className="text-tiny text-default-600">
                    Available Uploads
                  </span>
                  <div className="flex justify-between gap-2 text-tiny">
                    <span className="text-default-500">NDA</span>
                    <span className="text-success">
                      {getRemainingCredits(
                        selectedAccount?.features.ndaCreditsRemaining,
                      )}
                    </span>
                  </div>
                  <div className="flex justify-between gap-2 text-tiny">
                    <span className="text-default-500">Sales Contract </span>
                    <span className="text-success">
                      {getRemainingCredits(
                        selectedAccount?.features.creditsRemaining,
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </DropdownItem>
          </DropdownSection>

          <DropdownSection showDivider aria-label="Theme">
            <DropdownItem
              key="theme"
              isReadOnly
              className="cursor-default select-none opacity-100 data-[hover=true]:bg-transparent"
            >
              <SidebarThemeSelector />
            </DropdownItem>
          </DropdownSection>

          <DropdownSection aria-label="Actions">
            <DropdownItem key="settings" onPress={navigateToSettings}>
              Settings
            </DropdownItem>
            <DropdownItem
              key="help-and-feedback"
              isReadOnly
              endContent={<LinkSquareIcon className="size-3.5" />}
              href="https://docsum-inc.notion.site/Docsum-Help-Center-Knowledge-Base-180b4ab0e3748046b2dcf5e8447232b7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help & Feedback
            </DropdownItem>
            <DropdownItem key="logout" onPress={() => logOut()}>
              Log Out
            </DropdownItem>
          </DropdownSection>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
})

SidebarUser.displayName = 'SidebarUser'

import { useCallback, useMemo } from 'react'
import { ExternalToast, ToastT, toast as sonnerToast, useSonner } from 'sonner'

export enum ToastType {
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
  INFO = 'info',
}

type CustomExternalToast = Omit<ExternalToast, 'classNames'>

const toastFuncMap = {
  [ToastType.ERROR]: sonnerToast.error,
  [ToastType.WARNING]: sonnerToast.warning,
  [ToastType.SUCCESS]: sonnerToast.success,
  [ToastType.INFO]: sonnerToast.info,
}

const toastThemeMap: { [key in ToastType]: ToastT['classNames'] } = {
  [ToastType.ERROR]: {
    title: 'text-red-700 dark:text-red-400',
    icon: 'text-red-500 dark:text-red-400/90',
    toast: 'border-red-600/20 dark:border-red-400/20',
    closeButton: '[&>svg]:!border-red-600 dark:[&>svg]:!border-red-400/70',
  },
  [ToastType.WARNING]: {
    title: 'text-yellow-600 dark:text-yellow-600',
    icon: 'text-yellow-500 dark:text-yellow-500/90',
    toast: 'border-yellow-600/20 dark:border-yellow-500/20',
    closeButton:
      '[&>svg]:!border-yellow-500 dark:[&>svg]:!border-yellow-500/70',
  },
  [ToastType.SUCCESS]: {
    title: 'text-lime-600 dark:text-lime-500',
    icon: 'text-lime-500 dark:text-lime-500/90',
    toast: 'border-lime-600/20 dark:border-lime-500/20',
    closeButton: '[&>svg]:!border-lime-600 dark:[&>svg]:!border-lime-500/70',
  },
  [ToastType.INFO]: {
    title: 'text-sky-700 dark:text-sky-500',
    icon: 'text-sky-500 dark:text-sky-500/80',
    toast: 'border-sky-700/20 dark:border-sky-500/20',
    closeButton: '[&>svg]:!border-sky-700 dark:[&>svg]:!border-sky-500/70',
  },
}

type ToastFunction = (
  message: string,
  data?: CustomExternalToast | undefined,
) => string | number

type DocsumToast = {
  error: ToastFunction
  warning: ToastFunction
  success: ToastFunction
  info: ToastFunction
  message: typeof sonnerToast.message
  dismiss: typeof sonnerToast.dismiss
  loading: typeof sonnerToast.loading
  promise: typeof sonnerToast.promise
}

export default function useToast() {
  const { toasts: sonnerToasts } = useSonner()

  const createToast = useCallback(
    (
      type: ToastType,
      message: string,
      data?: CustomExternalToast | undefined,
    ) => {
      const toastFunc = toastFuncMap[type]
      if (!toastFunc) return sonnerToast.message(message, data)

      return toastFunc(message, {
        ...data,
        classNames: toastThemeMap[type],
      })
    },
    [],
  )

  const toast = useMemo<DocsumToast>(
    () => ({
      error: (...args) => createToast(ToastType.ERROR, args[0], args[1]),
      warning: (...args) => createToast(ToastType.WARNING, args[0], args[1]),
      success: (...args) => createToast(ToastType.SUCCESS, args[0], args[1]),
      info: (...args) => createToast(ToastType.INFO, args[0], args[1]),
      message: sonnerToast.message,
      dismiss: sonnerToast.dismiss,
      loading: sonnerToast.loading,
      promise: sonnerToast.promise,
    }),
    [createToast],
  )

  const isToastVisible = useCallback(
    (toastId: string | number) => {
      return sonnerToasts.some(toast => toast.id === toastId)
    },
    [sonnerToasts],
  )

  return { toast, isToastVisible }
}

import { DocumentFileTypeExtension } from '@/models/enums'

export const fileTypeMimes: Record<DocumentFileTypeExtension, string> = {
  [DocumentFileTypeExtension.PDF]: 'application/pdf',
  [DocumentFileTypeExtension.DOCX]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
} as const

export const fileTypeMimeSubtypes: Record<DocumentFileTypeExtension, string> = {
  [DocumentFileTypeExtension.PDF]: 'pdf',
  [DocumentFileTypeExtension.DOCX]:
    'vnd.openxmlformats-officedocument.wordprocessingml.document',
} as const

// Represents internal document types. Handling internal document
// types statically with enums is deprecated since new internal document types

import { DocumentFieldType } from '@/models/enums'

// TODO: Should come from the backend
export const documentTypeNames = {
  OTHER: 'OTHER',
  MUTUAL_NDA: 'MNDA',
  UNILATERAL_NDA: 'UNDA',
  MASTER_SERVICE_AGREEMENT: 'MSA',
  DATA_PROCESSING_AGREEMENT: 'DPA',
  TERMS_OF_SERVICE: 'TOS',
  PRIVACY_POLICY: 'PP',
  BUSINESS_ASSOCIATE_AGREEMENT: 'BAA',
}

export const DEFAULT_OTHER_DOCUMENT_TYPE = {
  id: 1,
  slug: 'OTHER',
  name: 'Other',
}

// TODO: figure out a better way to do this...
// 2 and 3 are the IDs for MUTUAL_NDA and UNILATERAL_NDA
export const NDA_DOCUMENTS_TYPE_IDS = [2, 3]

export const DEFAULT_DOCUMENT_LONG_FIELDS = [
  DocumentFieldType.CLAUSE,
  DocumentFieldType.LIST,
]

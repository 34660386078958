import { Icon } from '@iconify/react'
import { Switch } from '@heroui/switch'
import React from 'react'

import { useIsDarkTheme } from '@/hooks'
import { cn } from '@/utils'

export const SidebarThemeSelector = () => {
  const { isDark, toggleTheme } = useIsDarkTheme()

  return (
    <div className="flex items-center justify-between gap-2">
      <div className="text-small text-default-800">Theme</div>
      <Switch
        color="default"
        size="md"
        defaultSelected={isDark}
        checked={isDark}
        onChange={toggleTheme}
        thumbIcon={({ isSelected, className }) =>
          isSelected ? (
            <Icon
              className={className}
              icon="material-symbols-light:dark-mode-outline"
            />
          ) : (
            <Icon
              className={className}
              icon="material-symbols-light:light-mode-outline"
            />
          )
        }
        classNames={{
          base: cn(
            'ring-1 ring-default-200 rounded-full hover:ring-2 hover:ring-primary-500',
            { 'ring-default-300': isDark },
          ),
          wrapper: cn('mr-0 h-6', {
            '!bg-default-100': !isDark,
            '!bg-default-200': isDark,
          }),
          thumb: cn({
            'bg-default-50': isDark,
            'bg-white/80': !isDark,
          }),
          thumbIcon: cn('w-4 h-4', {
            'text-default-800': isDark,
          }),
        }}
      />
    </div>
  )
}

SidebarThemeSelector.displayName = 'SidebarThemeSelector'

import { Tooltip, TooltipProps } from '@heroui/tooltip'
import { Kbd, KbdProps } from '@heroui/kbd'
import { forwardRef } from 'react'

import { cn } from '@/utils'

export interface UITooltipProps
  extends Omit<TooltipProps, 'shadow' | 'motionProps'> {
  children?: React.ReactNode
  shortcutText?: string
  shortcutKey?: KbdProps['keys']
}

const UITooltip = forwardRef<HTMLElement | null, UITooltipProps>(
  (
    {
      children,
      shortcutKey,
      shortcutText,
      content,
      classNames,
      ...props
    }: UITooltipProps,
    ref,
  ) => (
    <Tooltip
      ref={ref}
      delay={1000}
      closeDelay={0}
      shadow={undefined}
      size="sm"
      placement="bottom"
      motionProps={{
        variants: {
          exit: {
            opacity: 0,
            transition: {
              duration: 0.1,
              ease: 'easeIn',
            },
          },
          enter: {
            opacity: 1,
            transition: {
              duration: 0.1,
              ease: 'easeOut',
            },
          },
        },
      }}
      {...props}
      classNames={{
        ...classNames,
        content: [
          'text-tiny shadow rounded ring-1 ring-default-200 font-normal text-default-500 bg-white dark:font-light dark:text-default-700 dark:bg-[#1C1C1E] dark:ring-default-300/60',
          classNames?.content,
        ],
        base: [
          'rounded',
          'before:bg-white dark:before:bg-[#1C1C1E]',
          'data-[arrow=true]:before:shadow-none data-[arrow=true]:before:z-10',
          'data-[arrow=true]:before:border-default-200 data-[arrow=true]:before:dark:border-default-300/60',
          'data-[placement=left]:before:-right-[calc(theme(spacing.5)/4_+_0.5px)] data-[placement=left]:before:border-r-1 data-[placement=left]:before:border-t-1',
          'data-[placement=right]:before:-left-[calc(theme(spacing.5)/4_+_0.5px)] data-[placement=right]:before:border-l-1 data-[placement=right]:before:border-b-1',
          classNames?.base,
        ],
      }}
      content={
        shortcutText || shortcutKey ? (
          <div className="flex items-center gap-x-2 text-tiny">
            {content}
            <Kbd
              keys={shortcutKey}
              classNames={{
                abbr: cn(
                  'text-foreground-500',
                  shortcutKey === 'enter' ? 'mt-1' : '',
                ),
                base: 'text-tiny rounded-md h-[22px] text-foreground-500',
              }}
            >
              {shortcutText}
            </Kbd>
          </div>
        ) : (
          content
        )
      }
    >
      {children}
    </Tooltip>
  ),
)

UITooltip.displayName = 'UITooltip'

export default UITooltip

import { useQueries } from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { appSettingsApi, documentTypesApi, filtersApi } from '@/services/api'
import { featuresApi } from '@/services/api'

import { useDocumentTypes } from './document-types'
import { useGetFeatures } from './features/useGetFeatures'
import { useGetAppSettings } from './app-settings/useGetAppSettings'
import { useGetSupportedFilters } from './filters/useGetSupportedFilters'

export function useLoadInitialData() {
  useQueries({
    queries: [
      {
        queryKey: QKEY.FEATURES,
        queryFn: ({ signal }) => featuresApi.getFeatures({ signal }),
      },
      {
        queryKey: QKEY.DOCUMENT_TYPES.getAll,
        queryFn: ({ signal }) => documentTypesApi.getAll({ signal }),
      },
      {
        queryKey: QKEY.APP_SETTINGS.getAll,
        queryFn: ({ signal }) => appSettingsApi.getAll({ signal }),
      },
      {
        queryKey: QKEY.FILTERS.getAllSupported,
        queryFn: ({ signal }) => filtersApi.getSupportedFilters({ signal }),
      },
    ],
  })

  // HINT: feed current hooks from cache
  useGetFeatures()
  useDocumentTypes()
  useGetAppSettings()
  useGetSupportedFilters()
}

import React, { memo } from 'react'

import { ShieldIcon, UITag, UITooltip } from '@/components/ui'
import { cn } from '@/utils/class-names'

interface StandardTagProps {
  className?: string
  isCompact?: boolean
  adjustForMobile?: boolean
}

const StandardTag = memo(
  ({
    className,
    isCompact = false,
    adjustForMobile = false,
  }: StandardTagProps) => (
    <>
      <UITag
        color="info"
        className={cn(
          className,
          isCompact && 'scale-80',
          adjustForMobile && 'hidden sm:flex',
        )}
      >
        Standard
      </UITag>

      {adjustForMobile && (
        <UITooltip content="Standard field">
          <div className={cn('sm:hidden flex items-center', className)}>
            <UITag color="info" className="px-1">
              <ShieldIcon className="h-3.5 w-3.5 text-sky-700 dark:text-sky-400" />
            </UITag>
          </div>
        </UITooltip>
      )}
    </>
  ),
)

StandardTag.displayName = 'Shared.StandardTag'

export default StandardTag

import React, { useCallback, useMemo, useState } from 'react'

import { useSelectedAccount } from '@/hooks/useSelectedAccount'
import {
  useUploadDocumentFiles,
  useUploadNewDocumentVersionFile,
} from '@/hooks/mutations'
import {
  PlusIcon,
  UIAlert,
  UIButton,
  UIIconButton,
  UIModal,
  useToast,
  TrashCanIcon,
} from '@/components/ui'
import { useUser } from '@/hooks/queries/useUser'
import {
  DocumentTypeSelector,
  SupportLink,
  UploadcareFileUploaderInline,
  UploadedFileEntry,
  useUploaderCtxProvider,
} from '@/components/shared'
import { DocumentFileTypeExtension } from '@/models/enums'
import { DocsumDocumentType } from '@/models/api'
import { cn } from '@/utils'

type FileMetadata = {
  documentTypeId?: DocsumDocumentType['id']
  success?: boolean
}

interface UploadFilesDialogProps {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  onClose(): void
  title?: string
  description?: string
  isSingleUpload: false
  canSelectDocumentType?: boolean
}

interface SingleUploadFilesDialogProps {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
  onClose(): void
  title?: string
  description?: string
  isSingleUpload: true
  metadata: {
    documentId: string
  }
  allowedFormats: DocumentFileTypeExtension[]
  canSelectDocumentType?: boolean
  onSuccess(jobId: string): void
}

const UploadFilesDialog = ({
  isOpen,
  onChange,
  onClose,
  title,
  description,
  isSingleUpload,
  canSelectDocumentType = false,
  ...props
}: UploadFilesDialogProps | SingleUploadFilesDialogProps) => {
  const [isUploading, setIsUploading] = useState(false)

  const [selectedFiles, setSelectedFiles] = useState<Map<string, FileMetadata>>(
    new Map(),
  )

  const [isShowingFilesList, setIsShowingFilesList] = useState(false)

  const [canBeDone, setCanBeDone] = useState(false)

  const [invalidUploads, setInvalidUploads] = useState<UploadedFileEntry[]>([])
  const {
    ctxProviderRef,
    resetUploaderState,
    addMoreFiles,
    uploadFiles,
    removeFileByInternalId,
    getCollectionState,
  } = useUploaderCtxProvider()

  const selectedAccount = useSelectedAccount()

  const {
    mutateAsync: uploadDocumentFiles,
    isPending,
    error: uploadDocumentFilesError,
  } = useUploadDocumentFiles()

  const {
    mutateAsync: uploadDocumentVersionFile,
    isPending: isUploadingVersionFile,
  } = useUploadNewDocumentVersionFile()

  const { data: user } = useUser()

  const { toast } = useToast()

  const uploaderMetadata = useMemo(
    () => ({
      userId: user?.id || '',
      accountId: selectedAccount?.id || '',
    }),
    [user, selectedAccount],
  )

  const isPdfAllowed =
    !(props as SingleUploadFilesDialogProps).allowedFormats ||
    (props as SingleUploadFilesDialogProps).allowedFormats.includes(
      DocumentFileTypeExtension.PDF,
    )

  const isBusy = useMemo(() => {
    return isUploading || isPending || isUploadingVersionFile
  }, [isUploading, isPending, isUploadingVersionFile])

  const showSuccessUploadToast = useCallback(
    (count = 1, description?: string) => {
      return toast.success(
        count > 1 ? `${count} files uploaded` : 'File uploaded',
        description ? { description } : undefined,
      )
    },
    [toast],
  )

  const showFailedUploadToast = useCallback(
    (count = 1) => {
      toast.error(
        count > 1 ? `${count} file uploads failed` : 'File upload failed',
      )
    },
    [toast],
  )

  const onFilesUploadedSuccessHandler = useCallback(
    async (successEntries: UploadedFileEntry[]) => {
      const files = successEntries.map(
        ({ name, uuid, cdnUrl, internalId, fileInfo }) => {
          return {
            originalName: name,
            externalId: uuid,
            url: cdnUrl,
            tags: [],
            documentTypeId: selectedFiles.get(internalId)?.documentTypeId,
            fileMimeSubtype: fileInfo.contentInfo?.mime?.subtype,
          }
        },
      )

      if (!files.length) return

      if (isSingleUpload) {
        try {
          const response = await uploadDocumentVersionFile({
            documentId: (props as SingleUploadFilesDialogProps).metadata
              .documentId,
            file: files[0],
          })

          setIsUploading(false)

          const { onSuccess } = props as SingleUploadFilesDialogProps
          onSuccess(response.jobId)
          onCloseHandler()
        } catch (error) {
          console.error('Error uploading single file', error)
          showFailedUploadToast()
        }
      } else {
        const uploadResults = await uploadDocumentFiles({ files })
        if (uploadResults) {
          const {
            successCount: successDocumentsCount,
            failedCount: failedDocumentsCount,
          } = uploadResults

          if (successDocumentsCount) {
            showSuccessUploadToast(successDocumentsCount)
          }

          if (failedDocumentsCount) {
            showFailedUploadToast(failedDocumentsCount)
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      showFailedUploadToast,
      uploadDocumentFiles,
      showSuccessUploadToast,
      isSingleUpload,
      uploadDocumentVersionFile,
      selectedFiles,
    ],
  )

  const onFilesUploadedFailedHandler = useCallback(
    (count: number) => {
      showFailedUploadToast(count)
    },
    [showFailedUploadToast],
  )

  const onFileAddedHandler = useCallback((id: string) => {
    setSelectedFiles(prev => {
      const newMap = new Map(prev)
      newMap.set(id, {})
      return newMap
    })

    setCanBeDone(false)
  }, [])

  const onFileRemovedHandler = useCallback(
    (id: string) => {
      setSelectedFiles(prev => {
        const newMap = new Map(prev)
        newMap.delete(id)
        return newMap
      })

      if (!isSingleUpload) {
        const { idleEntries } = getCollectionState()
        setCanBeDone(idleEntries.length === 0)
      }
    },
    [getCollectionState, isSingleUpload],
  )

  const onActivityChangeHandler = useCallback((activity: string) => {
    if (activity === 'start-from') {
      setIsShowingFilesList(false)
      setInvalidUploads([])
    } else if (activity === 'upload-list') {
      setIsShowingFilesList(true)
    }
  }, [])

  const onFinishedHandler = useCallback(() => {
    !isSingleUpload && setIsUploading(false)

    if (canSelectDocumentType) {
      const { idleEntries, successEntries, totalCount, successCount } =
        getCollectionState()

      const allFilesUploadedSuccessfully = successCount === totalCount

      const hasPendingFiles = idleEntries.length > 0

      if (allFilesUploadedSuccessfully && !hasPendingFiles) {
        setCanBeDone(true)
      }

      if (successEntries.length > 0) {
        setSelectedFiles(prev => {
          const newMap = new Map(prev)

          successEntries.forEach(({ internalId }) => {
            const metadata = newMap.get(internalId)
            newMap.set(internalId, { ...metadata, success: true })
          })
          return newMap
        })
      }
    }
  }, [getCollectionState, isSingleUpload, canSelectDocumentType])

  const onCloseHandler = useCallback(() => {
    setSelectedFiles(new Map())
    setIsShowingFilesList(false)
    setInvalidUploads([])
  }, [])

  // TODO: Need to update core HeroUI Modal component to latest version
  return (
    <UIModal
      scrollBehavior="outside"
      placement="center"
      contentClassName="max-w-full sm:max-w-fit sm:min-w-[500px] overflow-y-hidden"
      headerClassName="pt-7 pb-3"
      title={
        <div className="flex w-full flex-col items-center gap-1">
          <div className="text-2xl font-bold">{title}</div>
          <div className="text-small font-light text-default-500">
            {description}
          </div>
        </div>
      }
      isOpen={isOpen}
      onOpenChange={onChange}
      onClose={onCloseHandler}
      footerClassName={cn(
        isShowingFilesList && 'bg-default-50 dark:bg-black/20',
      )}
      footer={
        isShowingFilesList &&
        (closeModal => (
          <div className="flex w-full justify-between items-center gap-2">
            <UIButton
              size="sm"
              variant="flat"
              onPress={resetUploaderState}
              isDisabled={isBusy}
            >
              Clear
            </UIButton>

            <div className="flex items-center gap-2">
              {!isSingleUpload && (
                <UIButton
                  size="sm"
                  variant="flat"
                  startContent={<PlusIcon className="size-4" />}
                  onPress={addMoreFiles}
                  isDisabled={isBusy}
                >
                  Add more
                </UIButton>
              )}
              <UIButton
                size="sm"
                color="primary"
                onPress={canBeDone ? closeModal : uploadFiles}
                isDisabled={isBusy}
                isLoading={isUploading}
              >
                {canBeDone ? 'Done' : isUploading ? 'Uploading...' : 'Upload'}
              </UIButton>
            </div>
          </div>
        ))
      }
    >
      <div className="flex flex-col gap-y-4">
        <div className="sm:gap-x-2 flex">
          <div
            className={cn('flex-1', isShowingFilesList && 'lg:max-w-[600px]')}
          >
            <UploadcareFileUploaderInline
              hideNativeUploadListToolbar
              multiple={!isSingleUpload}
              metadata={uploaderMetadata}
              isDisabled={isBusy}
              contextProviderRef={ctxProviderRef}
              onUploading={() => {
                setIsUploading(true)
              }}
              onFinished={onFinishedHandler}
              onFilesUploadedSuccess={onFilesUploadedSuccessHandler}
              onFilesUploadedFailed={onFilesUploadedFailedHandler}
              onDone={onClose}
              allowedFormats={
                (props as SingleUploadFilesDialogProps).allowedFormats
              }
              onFileAdded={onFileAddedHandler}
              onFileRemoved={onFileRemovedHandler}
              onActivityChange={onActivityChangeHandler}
              onInvalidMimeTypeUpload={setInvalidUploads}
            />
          </div>

          {selectedFiles.size > 0 && isShowingFilesList && (
            <div className={cn('pr-1.5', !isSingleUpload && 'flex-1 max-w-96')}>
              <div className="h-[42px] flex items-center px-2.5">
                {canSelectDocumentType && (
                  <span className="text-small font-medium text-foreground-500">
                    Document Type
                  </span>
                )}
              </div>
              <div className="flex flex-col gap-y-1 w-full">
                {Array.from(selectedFiles).map(([id, metadata]) => (
                  <div
                    key={id}
                    className="flex h-[52px] items-center gap-2 sm:gap-4"
                  >
                    {canSelectDocumentType && (
                      <div className="flex h-full md:min-w-72 flex-1 items-center overflow-hidden bg-[#f7f7f7] dark:bg-[#1E1E20] rounded-lg px-2.5">
                        <DocumentTypeSelector
                          isDense
                          fullWidth
                          selectionMode="single"
                          placeholder="AI Analyzed"
                          showClearButton={
                            !!metadata.documentTypeId || !!metadata.success
                          }
                          isDisabled={isBusy || !!metadata.success}
                          selectedKeys={
                            metadata.documentTypeId
                              ? [metadata.documentTypeId.toString()]
                              : undefined
                          }
                          onChange={newDocumentTypeId => {
                            setSelectedFiles(prev => {
                              const newMap = new Map(prev)
                              newMap.set(id, {
                                documentTypeId: newDocumentTypeId as number,
                              })
                              return newMap
                            })
                          }}
                          onClear={() => {
                            setSelectedFiles(prev => {
                              const newMap = new Map(prev)
                              newMap.set(id, {
                                ...newMap.get(id),
                                documentTypeId: undefined,
                              })
                              return newMap
                            })
                          }}
                        />
                      </div>
                    )}

                    <UIIconButton
                      variant="light"
                      size="sm"
                      icon={TrashCanIcon}
                      color="danger"
                      onPress={() => {
                        removeFileByInternalId(id)
                      }}
                      isDisabled={isBusy}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {isPdfAllowed && (
        <div className="my-2 text-center text-tiny text-default-400">
          (Clause analysis is unavailable for PDF)
        </div>
      )}

      {!!uploadDocumentFilesError && (
        <div className="pt-4 w-full">
          <UIAlert
            color="error"
            title="Upload Failed"
            message={
              <span>
                There was an error uploading the document(s). Please contact
                support by emailing <SupportLink className="text-tinyPlus" />
              </span>
            }
          />
        </div>
      )}

      {invalidUploads.length > 0 && (
        <div className="pt-4 w-full">
          <UIAlert
            color="error"
            title="Some files cannot be processed"
            message={
              <p>
                The following files have an invalid mime type. Please contact
                support by emailing <SupportLink className="text-tinyPlus" />
              </p>
            }
          >
            <ul className="list-disc list-inside space-y-1 mt-1 text-tinyPlus">
              {invalidUploads.map(upload => (
                <li key={upload.fileInfo.uuid}>
                  {upload.fileInfo.name} (
                  {upload.fileInfo.contentInfo?.mime?.subtype})
                </li>
              ))}
            </ul>
          </UIAlert>
        </div>
      )}
    </UIModal>
  )
}

export default UploadFilesDialog

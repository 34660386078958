import { cn } from '@/utils'
import {
  ChatBubbleBottomCenterTextIcon as DefaultIcon,
  XCircleIcon as ErrorIcon,
  InformationCircleIcon as InfoIcon,
  CheckCircleIcon as SuccessIcon,
  ExclamationTriangleIcon as WarnIcon,
} from '@heroicons/react/20/solid'

import { memo, ReactNode } from 'react'

type AlertColor = 'default' | 'info' | 'success' | 'warning' | 'error'

export interface IUIAlertProps {
  color?: AlertColor
  title?: string
  message?: string | ReactNode
  dense?: boolean
  hideIcon?: boolean
  className?: string
  children?: string | React.ReactNode
  endContent?: React.ReactNode
}

const IconMap = {
  success: SuccessIcon,
  error: ErrorIcon,
  warning: WarnIcon,
  info: InfoIcon,
  default: DefaultIcon,
}

const colorMap = {
  default: {
    background: 'bg-gray-100 dark:bg-black/50',
    icon: 'text-gray-400 dark:text-gray-400',
    title: 'font-medium text-gray-600 dark:text-white',
    message: 'text-gray-500 dark:text-white/80',
  },
  success: {
    background: 'bg-green-50 dark:bg-green-900/25',
    icon: 'text-green-400 dark:text-green-600',
    title: 'font-medium text-green-800 dark:text-green-300',
    message: 'text-green-700 dark:text-green-200',
  },
  // For normal Red error
  // error: {
  //   background: 'bg-red-50 dark:bg-red-900/20',
  //   icon: 'text-red-400 dark:text-red-600',
  //   title: 'font-medium text-red-800 dark:text-red-400/90',
  //   message: 'text-red-700 dark:text-red-200',
  // },
  error: {
    background:
      'bg-danger-50 dark:bg-danger-200/30 ring-danger-500 dark:ring-danger-300 ring-[1px]',
    icon: 'text-danger-400 dark:text-danger-500',
    title: 'font-medium text-danger-700 dark:text-danger-800',
    message: 'text-danger-600 dark:text-danger-700',
  },
  warning: {
    background: 'bg-yellow-50 dark:bg-yellow-900/20',
    icon: 'text-yellow-400 dark:text-yellow-600',
    title: 'font-medium text-yellow-800 dark:text-yellow-400',
    message: 'text-yellow-700 dark:text-yellow-100',
  },
  info: {
    background:
      'bg-blue-50 dark:bg-blue-900/20 ring-blue-500 dark:ring-blue-900 ring-[1px]',
    icon: 'text-blue-400 dark:text-blue-600',
    title: 'font-medium text-blue-800 dark:text-blue-200',
    message: 'text-blue-700 dark:text-blue-500',
  },
}

const UIAlert = memo(
  ({
    color = 'default',
    dense = false,
    message,
    title,
    className = '',
    hideIcon = false,
    children,
    endContent,
  }: IUIAlertProps) => {
    const Icon = IconMap[color as AlertColor]
    const colorClassNames = colorMap[color as AlertColor]

    return (
      <div
        className={cn(
          dense ? 'py-1.5' : 'py-3',
          className,
          'rounded-medium px-4',
          colorClassNames.background,
        )}
      >
        <div className="flex items-start gap-x-1">
          {!hideIcon && (
            <div className="flex-none relative size-9 grid place-items-center rounded-full">
              <Icon
                className={cn(
                  dense ? 'h-4 w-4' : 'h-6 w-6',
                  colorClassNames.icon,
                  'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                )}
                aria-hidden="true"
              />
            </div>
          )}

          <div
            className={cn(
              'h-full flex-grow min-h-9 flex flex-col box-border items-start text-inherit justify-center',
              hideIcon ? '' : 'ms-2',
              colorClassNames.message,
              'w-full',
            )}
          >
            {title && (
              <div
                className={cn(
                  'w-full block tracking-tight text-small leading-5',
                  colorClassNames.title,
                )}
              >
                {title}
              </div>
            )}

            {message && (
              <p className="mb-0 text-tinyPlus text-inherit font-light leading-4">
                {message}
              </p>
            )}

            {children}

            {endContent && (
              <div className="mt-2 flex md:hidden">{endContent}</div>
            )}
          </div>

          {endContent && (
            <div className="ml-4 hidden pt-0.5 md:flex">{endContent}</div>
          )}
        </div>
      </div>
    )
  },
)

UIAlert.displayName = 'UIAlert'

export default UIAlert

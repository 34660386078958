import { useAtom } from 'jotai'

import {
  currentLoadingToastIdAtom,
  eventStreamMessageIdAtom,
  globalStore,
} from '@/services/state/global'

export function useStreamEventsState() {
  const [eventStreamMessageId, setEventStreamMessageId] = useAtom(
    eventStreamMessageIdAtom,
    {
      store: globalStore,
    },
  )

  const [currentLoadingToastId, setCurrentLoadingToastId] = useAtom(
    currentLoadingToastIdAtom,
    {
      store: globalStore,
    },
  )

  return {
    eventStreamMessageId,
    currentLoadingToastId,
    setEventStreamMessageId,
    setCurrentLoadingToastId,
  }
}

import React, { memo } from 'react'

import { useIsDarkTheme } from '@/hooks'
import { IconSvgProps } from '@/models/components'

const LogoFull = memo((props: IconSvgProps) => {
  const { isDark } = useIsDarkTheme()

  if (isDark)
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 714 161"
        {...props}
      >
        <path
          d="M0,91c24.865-.026,37.833-.464,46.824-7.634,2.049-1.634,3.908-3.493,5.542-5.542,7.17-8.991,7.607-21.959,7.634-46.824L0,91Z"
          fill="#72cda1"
        />
        <path
          d="M345.747,44.178c-1.634-2.049-3.493-3.908-5.542-5.542-9.575-7.636-23.657-7.636-51.822-7.636H67.5v.008c-.001,1.278-.004,2.52-.008,3.742h220.891c27.255,0,40.935,0,49.484,6.818,1.828,1.457,3.492,3.122,4.948,4.948,6.818,8.549,6.818,22.229,6.818,49.483s0,40.935-6.818,49.484c-1.456,1.825-3.121,3.49-4.948,4.948-8.549,6.817-22.229,6.817-49.484,6.817H65c-27.255,0-40.935,0-49.484-6.817-1.828-1.458-3.493-3.123-4.948-4.948-6.197-7.772-6.767-19.764-6.814-43.242v-3.75c-1.223.004-2.466.007-3.746.008H0c.007,26.506.229,40.035,7.636,49.322,1.634,2.049,3.493,3.908,5.542,5.542,9.575,7.636,23.657,7.636,51.822,7.636h223.383c28.165,0,42.247,0,51.822-7.636,2.049-1.634,3.908-3.493,5.542-5.542,7.636-9.575,7.636-23.657,7.636-51.822s0-42.247-7.636-51.822Z"
          fill="#72cda1"
        />
        <path
          d="M90.599,0v58.693c-2.769-3.195-6.06-5.868-9.9-7.992-4.028-2.226-8.485-3.646-13.354-4.291-.215,8.077-.732,14.723-1.977,20.407,5.226.084,9.806,1.305,13.731,3.684,4.066,2.468,7.232,5.899,9.5,10.3,2.266,4.399,3.399,9.468,3.399,15.2s-1.134,10.799-3.399,15.2c-2.269,4.4-5.434,7.834-9.5,10.299-4.068,2.469-8.833,3.7-14.3,3.7-5.333,0-10.034-1.23-14.1-3.7-4.069-2.465-7.268-5.899-9.6-10.299-2.334-4.4-3.5-9.466-3.5-15.2,0-.02.001-.039.001-.059-6.148,1.575-13.441,2.18-22.542,2.412.292,8.479,2.289,16.201,6.04,23.146,4.066,7.534,9.6,13.5,16.6,17.9,7,4.4,14.832,6.6,23.501,6.6,7.331,0,13.865-1.569,19.6-4.7,3.817-2.086,7.075-4.702,9.799-7.823v10.523h22V0h-22Z"
          fill="#fafaf7"
        />
        <path
          d="M287.483,70.901c4.2-2.466,9.031-3.701,14.5-3.701,4.666,0,8.965.835,12.9,2.5,3.931,1.669,7.3,4.169,10.1,7.5l14.399-14.6c-4.534-5.332-10.034-9.432-16.499-12.3-6.469-2.866-13.435-4.3-20.9-4.3-9.469,0-18.035,2.2-25.7,6.6-7.669,4.4-13.699,10.369-18.099,17.9-4.4,7.534-6.6,16.034-6.6,25.5,0,9.334,2.2,17.8,6.6,25.4,4.4,7.6,10.431,13.6,18.099,18,7.666,4.4,16.231,6.6,25.7,6.6,7.466,0,14.431-1.435,20.9-4.3,6.465-2.866,11.965-6.966,16.499-12.3l-14.399-14.6c-2.8,3.334-6.169,5.835-10.1,7.5-3.935,1.669-8.234,2.5-12.9,2.5-5.469,0-10.3-1.232-14.5-3.7-4.2-2.466-7.468-5.866-9.8-10.201-2.334-4.331-3.5-9.3-3.5-14.899,0-5.732,1.166-10.731,3.5-15.001,2.332-4.265,5.601-7.631,9.8-10.099Z"
          fill="#fafaf7"
        />
        <path
          d="M392.382,68c2.4-1.331,5.8-2,10.2-2,4.666,0,8.8.869,12.4,2.601,3.6,1.734,6.799,4.334,9.6,7.799l13.999-13.999c-4-5.466-8.968-9.565-14.899-12.3-5.934-2.731-12.769-4.1-20.5-4.1-7.335,0-13.635,1.201-18.9,3.6-5.269,2.4-9.334,5.8-12.2,10.2-2.869,4.4-4.3,9.669-4.3,15.8,0,5.734,1.2,10.4,3.6,14,2.4,3.599,5.499,6.434,9.3,8.5,3.799,2.068,7.8,3.7,12,4.9,4.2,1.2,8.2,2.369,12,3.5,3.8,1.134,6.9,2.5,9.301,4.1,2.4,1.6,3.599,3.869,3.599,6.799,0,2.801-1.268,4.935-3.799,6.4-2.535,1.469-6.269,2.2-11.2,2.2-5.468,0-10.369-.966-14.7-2.9-4.334-1.931-8.168-4.831-11.5-8.7l-13.999,14c3.065,3.735,6.665,6.9,10.799,9.501,4.132,2.6,8.666,4.599,13.6,6,4.932,1.4,10.066,2.1,15.4,2.1,11.2,0,20.066-2.731,26.6-8.2,6.531-5.465,9.799-12.865,9.799-22.199,0-5.866-1.2-10.632-3.599-14.3-2.401-3.666-5.5-6.6-9.301-8.801-3.799-2.199-7.799-3.931-12-5.199-4.2-1.266-8.199-2.431-12-3.5-3.799-1.066-6.9-2.332-9.3-3.8-2.401-1.466-3.6-3.531-3.6-6.2,0-2.531,1.2-4.466,3.6-5.8Z"
          fill="#fafaf7"
        />
        <path
          d="M520.18,102.8c0,7.069-1.7,12.5-5.1,16.3-3.4,3.799-8.3,5.7-14.7,5.7-4.134,0-7.7-.866-10.699-2.601-3-1.731-5.269-4.231-6.8-7.499-1.534-3.266-2.3-7.232-2.3-11.9v-54.8h-21.999v55.799c0,8.269,1.765,15.569,5.3,21.901,3.531,6.333,8.465,11.3,14.8,14.899,6.332,3.6,13.565,5.4,21.7,5.4,8.266,0,15.531-1.769,21.8-5.3,6.266-3.532,11.165-8.466,14.7-14.801,3.531-6.331,5.3-13.699,5.3-22.1v-55.799h-22v54.8Z"
          fill="#fafaf7"
        />
        <path
          d="M709,62.8c-3.334-5.331-7.8-9.465-13.4-12.399-5.601-2.931-11.869-4.401-18.8-4.401-6.799,0-13.034,1.5-18.7,4.5-5.314,2.812-9.704,6.665-13.199,11.525-3.148-4.952-7.307-8.837-12.501-11.625-5.468-2.931-11.6-4.401-18.4-4.401s-12.934,1.5-18.4,4.5c-3.547,1.946-6.607,4.392-9.199,7.32v-9.82h-22v95.999h22v-57.199c0-4.266.931-7.901,2.8-10.901,1.865-3,4.365-5.3,7.499-6.9,3.131-1.6,6.7-2.4,10.7-2.4,5.866,0,10.8,1.768,14.8,5.3,4,3.535,6,8.5,6,14.9v57.199h22v-57.199c0-4.266.931-7.901,2.8-10.901,1.865-3,4.4-5.3,7.6-6.9,3.2-1.6,6.731-2.4,10.6-2.4,5.866,0,10.8,1.768,14.8,5.3,4,3.535,6,8.5,6,14.9v57.199h22v-61.999c0-7.466-1.669-13.866-5-19.2Z"
          fill="#fafaf7"
        />
        <path
          d="M208.399,52.6c-7.6-4.4-16.134-6.6-25.6-6.6-9.334,0-17.8,2.235-25.4,6.701-7.6,4.469-13.635,10.434-18.1,17.899-4.469,7.469-6.7,15.869-6.7,25.2s2.231,17.8,6.7,25.4c4.466,7.6,10.5,13.635,18.1,18.1,7.6,4.469,16.065,6.7,25.4,6.7,9.466,0,18-2.231,25.6-6.7,7.6-4.466,13.632-10.5,18.1-18.1,4.466-7.6,6.7-16.065,6.7-25.4s-2.234-17.766-6.7-25.3c-4.469-7.531-10.5-13.5-18.1-17.9ZM207.1,110.8c-2.335,4.4-5.601,7.834-9.8,10.3-4.2,2.468-9.035,3.7-14.5,3.7-5.335,0-10.1-1.232-14.3-3.7-4.2-2.466-7.468-5.9-9.8-10.3-2.334-4.4-3.5-9.4-3.5-15s1.166-10.531,3.5-14.801c2.332-4.265,5.601-7.631,9.8-10.099,4.2-2.466,8.965-3.701,14.3-3.701,5.465,0,10.265,1.235,14.4,3.701,4.131,2.468,7.4,5.834,9.799,10.099,2.401,4.269,3.6,9.2,3.6,14.801s-1.169,10.6-3.5,15Z"
          fill="#fafaf7"
        />
      </svg>
    )

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 714 161"
      {...props}
    >
      <path
        d="M0,91c24.865-.026,37.833-.464,46.824-7.634,2.049-1.634,3.908-3.493,5.542-5.542,7.17-8.991,7.607-21.959,7.634-46.824L0,91Z"
        fill="#72cda1"
      />
      <path
        d="M345.747,44.178c-1.634-2.049-3.493-3.908-5.542-5.542-9.575-7.636-23.657-7.636-51.822-7.636H67.5v.008c-.028,26.232-.453,40.437-9.271,51.493-1.981,2.484-4.245,4.748-6.729,6.729C40.444,98.047,26.24,98.472.008,98.5h-.008c.007,26.506.229,40.035,7.636,49.322,1.634,2.049,3.493,3.908,5.542,5.542,9.575,7.636,23.657,7.636,51.822,7.636h223.383c28.165,0,42.247,0,51.822-7.636,2.049-1.634,3.908-3.493,5.542-5.542,7.636-9.575,7.636-23.657,7.636-51.822s0-42.247-7.636-51.822Z"
        fill="#d3f1d0"
      />
      <path
        d="M90.599,0v58.693c-2.769-3.195-6.06-5.868-9.9-7.992-4.028-2.226-8.485-3.646-13.354-4.291-.215,8.077-.732,14.723-1.977,20.407,5.226.084,9.806,1.305,13.731,3.684,4.066,2.468,7.232,5.899,9.5,10.3,2.266,4.399,3.399,9.468,3.399,15.2s-1.134,10.799-3.399,15.2c-2.269,4.4-5.434,7.834-9.5,10.299-4.068,2.469-8.833,3.7-14.3,3.7-5.333,0-10.034-1.23-14.1-3.7-4.069-2.465-7.268-5.899-9.6-10.299-2.334-4.4-3.5-9.466-3.5-15.2,0-.02.001-.039.001-.059-6.148,1.575-13.441,2.18-22.542,2.412.292,8.479,2.289,16.201,6.04,23.146,4.066,7.534,9.6,13.5,16.6,17.9,7,4.4,14.832,6.6,23.501,6.6,7.331,0,13.865-1.569,19.6-4.7,3.817-2.086,7.075-4.702,9.799-7.823v10.523h22V0h-22Z"
        fill="#183a28"
      />
      <path
        d="M287.483,70.901c4.2-2.466,9.031-3.701,14.5-3.701,4.666,0,8.965.835,12.9,2.5,3.931,1.669,7.3,4.169,10.1,7.5l14.399-14.6c-4.534-5.332-10.034-9.432-16.499-12.3-6.469-2.866-13.435-4.3-20.9-4.3-9.469,0-18.035,2.2-25.7,6.6-7.669,4.4-13.699,10.369-18.099,17.9-4.4,7.534-6.6,16.034-6.6,25.5,0,9.334,2.2,17.8,6.6,25.4,4.4,7.6,10.431,13.6,18.099,18,7.666,4.4,16.231,6.6,25.7,6.6,7.466,0,14.431-1.435,20.9-4.3,6.465-2.866,11.965-6.966,16.499-12.3l-14.399-14.6c-2.8,3.334-6.169,5.835-10.1,7.5-3.935,1.669-8.234,2.5-12.9,2.5-5.469,0-10.3-1.232-14.5-3.7-4.2-2.466-7.468-5.866-9.8-10.201-2.334-4.331-3.5-9.3-3.5-14.899,0-5.732,1.166-10.731,3.5-15.001,2.332-4.265,5.601-7.631,9.8-10.099Z"
        fill="#183a28"
      />
      <path
        d="M392.382,68c2.4-1.331,5.8-2,10.2-2,4.666,0,8.8.869,12.4,2.601,3.6,1.734,6.799,4.334,9.6,7.799l13.999-13.999c-4-5.466-8.968-9.565-14.899-12.3-5.934-2.731-12.769-4.1-20.5-4.1-7.335,0-13.635,1.201-18.9,3.6-5.269,2.4-9.334,5.8-12.2,10.2-2.869,4.4-4.3,9.669-4.3,15.8,0,5.734,1.2,10.4,3.6,14,2.4,3.599,5.499,6.434,9.3,8.5,3.799,2.068,7.8,3.7,12,4.9,4.2,1.2,8.2,2.369,12,3.5,3.8,1.134,6.9,2.5,9.301,4.1,2.4,1.6,3.599,3.869,3.599,6.799,0,2.801-1.268,4.935-3.799,6.4-2.535,1.469-6.269,2.2-11.2,2.2-5.468,0-10.369-.966-14.7-2.9-4.334-1.931-8.168-4.831-11.5-8.7l-13.999,14c3.065,3.735,6.665,6.9,10.799,9.501,4.132,2.6,8.666,4.599,13.6,6,4.932,1.4,10.066,2.1,15.4,2.1,11.2,0,20.066-2.731,26.6-8.2,6.531-5.465,9.799-12.865,9.799-22.199,0-5.866-1.2-10.632-3.599-14.3-2.401-3.666-5.5-6.6-9.301-8.801-3.799-2.199-7.799-3.931-12-5.199-4.2-1.266-8.199-2.431-12-3.5-3.799-1.066-6.9-2.332-9.3-3.8-2.401-1.466-3.6-3.531-3.6-6.2,0-2.531,1.2-4.466,3.6-5.8Z"
        fill="#183a28"
      />
      <path
        d="M520.18,102.8c0,7.069-1.7,12.5-5.1,16.3-3.4,3.799-8.3,5.7-14.7,5.7-4.134,0-7.7-.866-10.699-2.601-3-1.731-5.269-4.231-6.8-7.499-1.534-3.266-2.3-7.232-2.3-11.9v-54.8h-21.999v55.799c0,8.269,1.765,15.569,5.3,21.901,3.531,6.333,8.465,11.3,14.8,14.899,6.332,3.6,13.565,5.4,21.7,5.4,8.266,0,15.531-1.769,21.8-5.3,6.266-3.532,11.165-8.466,14.7-14.801,3.531-6.331,5.3-13.699,5.3-22.1v-55.799h-22v54.8Z"
        fill="#183a28"
      />
      <path
        d="M709,62.8c-3.334-5.331-7.8-9.465-13.4-12.399-5.601-2.931-11.869-4.401-18.8-4.401-6.799,0-13.034,1.5-18.7,4.5-5.314,2.812-9.704,6.665-13.199,11.525-3.148-4.952-7.307-8.837-12.501-11.625-5.468-2.931-11.6-4.401-18.4-4.401s-12.934,1.5-18.4,4.5c-3.547,1.946-6.607,4.392-9.199,7.32v-9.82h-22v95.999h22v-57.199c0-4.266.931-7.901,2.8-10.901,1.865-3,4.365-5.3,7.499-6.9,3.131-1.6,6.7-2.4,10.7-2.4,5.866,0,10.8,1.768,14.8,5.3,4,3.535,6,8.5,6,14.9v57.199h22v-57.199c0-4.266.931-7.901,2.8-10.901,1.865-3,4.4-5.3,7.6-6.9,3.2-1.6,6.731-2.4,10.6-2.4,5.866,0,10.8,1.768,14.8,5.3,4,3.535,6,8.5,6,14.9v57.199h22v-61.999c0-7.466-1.669-13.866-5-19.2Z"
        fill="#183a28"
      />
      <path
        d="M208.399,52.6c-7.6-4.4-16.134-6.6-25.6-6.6-9.334,0-17.8,2.235-25.4,6.701-7.6,4.469-13.635,10.434-18.1,17.899-4.469,7.469-6.7,15.869-6.7,25.2s2.231,17.8,6.7,25.4c4.466,7.6,10.5,13.635,18.1,18.1,7.6,4.469,16.065,6.7,25.4,6.7,9.466,0,18-2.231,25.6-6.7,7.6-4.466,13.632-10.5,18.1-18.1,4.466-7.6,6.7-16.065,6.7-25.4s-2.234-17.766-6.7-25.3c-4.469-7.531-10.5-13.5-18.1-17.9ZM207.1,110.8c-2.335,4.4-5.601,7.834-9.8,10.3-4.2,2.468-9.035,3.7-14.5,3.7-5.335,0-10.1-1.232-14.3-3.7-4.2-2.466-7.468-5.9-9.8-10.3-2.334-4.4-3.5-9.4-3.5-15s1.166-10.531,3.5-14.801c2.332-4.265,5.601-7.631,9.8-10.099,4.2-2.466,8.965-3.701,14.3-3.701,5.465,0,10.265,1.235,14.4,3.701,4.131,2.468,7.4,5.834,9.799,10.099,2.401,4.269,3.6,9.2,3.6,14.801s-1.169,10.6-3.5,15Z"
        fill="#183a28"
      />
    </svg>
  )
})

LogoFull.displayName = 'Layout.Sidebar.LogoFull'

export default LogoFull

import { QKEY } from '@/constants/queryKeys'
import {
  CKEditorDocxToHtmlMetadata,
  DocsumDocumentType,
  VersionedDocument,
} from '@/models/api'
import { DocumentFileTypeExtension, ProcessingStatus } from '@/models/enums'

import { useAuthorizedQuery } from '../useAuthorizedQuery'

export function useDocument(documentId: string | undefined) {
  return useAuthorizedQuery<VersionedDocument | null>(
    QKEY.DOCUMENT(documentId),
    {
      enabled: !!documentId,
      queryFn: client =>
        client
          .get<{
            id: string
            filename: string
            metadata_id: string
            document_type: DocsumDocumentType
            file_type: DocumentFileTypeExtension
            created_at: string
            tags: string[]
            archived: boolean
            recordName: string
            document_version: {
              id: string
              num_pages: number
              version_number: number
              contents: string[]
              ckeditor_conversion: CKEditorDocxToHtmlMetadata
              document_info_processing_status: ProcessingStatus
              flags_processing_status: ProcessingStatus
              redlines_processing_status: ProcessingStatus
              has_track_changes: boolean
              has_comments: boolean
              created_at: string
              updated_at: string
              playbook_id: number
              is_from_import: boolean
            }
          }>(`/document/${documentId}`)
          .then(res => {
            const document = res.data
            return {
              id: document.id,
              filename: document.filename,
              metadataId: document.metadata_id,
              documentType: document.document_type,
              fileType: document.file_type,
              createdAt: document.created_at,
              tags: document.tags,
              archived: document.archived,
              recordName: document.recordName,
              documentVersion: {
                id: document.document_version.id,
                documentInfoProcessingStatus:
                  document.document_version.document_info_processing_status,
                flagsProcessingStatus:
                  document.document_version.flags_processing_status,
                redlinesProcessingStatus:
                  document.document_version.redlines_processing_status,
                hasTrackChanges: document.document_version.has_track_changes,
                hasComments: document.document_version.has_comments,
                ckeditorConversion:
                  document.document_version.ckeditor_conversion,
                numPages: document.document_version.num_pages,
                createdAt: document.document_version.created_at,
                updatedAt: document.document_version.updated_at,
                versionNumber: document.document_version.version_number,
                playbookId: document.document_version.playbook_id,
                isFromImport: document.document_version.is_from_import,
              },
            }
          }),
      error: 'Failed to load document',
    },
  )
}

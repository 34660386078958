import { atom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { APP_LOCAL_STORAGE_KEY_PREFIX } from '@/constants'
import { AppTheme } from '@/models'

export const themeAtom = atomWithStorage<AppTheme>(
  `${APP_LOCAL_STORAGE_KEY_PREFIX}theme`,
  AppTheme.Light,
)

export const isSidebarCollapsedAtom = atom(false)

export const isSidebarHiddenAtom = atom(false)

export const routerStateAtom = atom<Record<string, unknown> | undefined>(
  undefined,
)

export const eventStreamMessageIdAtom = atom<string | undefined>(undefined)

export const currentLoadingToastIdAtom = atom<string | number | undefined>(
  undefined,
)

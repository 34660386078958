import React, { PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'

import { useIsDarkTheme } from '@/hooks'
import { useLoadInitialData } from '@/hooks/queries'

import { IdleTimerModal } from './IdleTimerModal'
import Sidebar from './Sidebar'
import { NewDocumentVersionReadyNotification } from '../shared'

const UIToaster = dynamic(
  () => import('@/components/ui/Toaster').then(mod => mod.UIToaster),
  {
    ssr: false,
  },
)

const PrivateLayout = ({ children }: PropsWithChildren) => {
  const { currentTheme } = useIsDarkTheme()

  // TODO: should be in SSR once we move to App Router
  useLoadInitialData()

  return (
    <div className="relative flex h-full min-h-full w-full items-stretch overflow-hidden">
      <UIToaster theme={currentTheme} />

      <IdleTimerModal />

      <Sidebar />

      <NewDocumentVersionReadyNotification />

      <div className="min-w-0 min-h-0 h-screen flex flex-col flex-1">
        {children}
      </div>
    </div>
  )
}

PrivateLayout.displayName = 'PrivateLayout'

export default PrivateLayout

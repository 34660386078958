import { EventSourcePolyfill } from 'event-source-polyfill'

import { API_URL } from '@/constants/api'

import BaseApi from './base'
import { getHeaders } from './client'

export class StreamEventsApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'stream-events'
  }

  getEventSourceStream(eventId: string, token: string) {
    const headers = getHeaders({ token })
    return new EventSourcePolyfill(
      `${API_URL}/${this.resourcePath}/${eventId}`,
      {
        ...headers,
      },
    )
  }
}

const streamEventsApi = new StreamEventsApi()

export default streamEventsApi

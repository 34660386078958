export enum DocumentFieldType {
  TIMESTAMP = 'TIMESTAMP',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  DECIMAL = 'NUMERIC',
  BOOLEAN = 'BOOLEAN',
  CLAUSE = 'CLAUSE',
  DURATION = 'DURATION',
  SELECT = 'SELECT',
  CURRENCY = 'CURRENCY',
  LIST = 'LIST',
}

import {
  useMutation,
  useQueryClient,
  QueryFilters,
} from '@tanstack/react-query'
import { useRouter } from 'next/router'

import { QKEY } from '@/constants/queryKeys'
import { useAuthContext } from '@/hooks/useAuthContext'
import { accountApi } from '@/services/api'
import { User } from '@/models/user'

export function useSwitchAccount() {
  const router = useRouter()

  const { updateToken } = useAuthContext()

  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: QKEY.ACCOUNTS.switch,
    mutationFn: (toAccountId: string) => accountApi.switchAccount(toAccountId),
    onMutate: async toAccountId => {
      const userQueryKey = QKEY.USER

      await queryClient.cancelQueries({ queryKey: userQueryKey })

      const previousUserData = queryClient.getQueryData(userQueryKey)

      queryClient.setQueryData<User | null>(userQueryKey, previousUser => {
        if (!previousUser) return null

        return {
          ...previousUser,
          accountId: toAccountId,
        }
      })

      return { previousUserData, userQueryKey }
    },
    onSuccess({ accessToken }, _, _context) {
      updateToken(accessToken, async () => {
        const accountGenericDependentQueries = [
          {
            queryKey: [QKEY.DOCUMENT_VERSIONS.getFieldValues()[0]],
            exact: false,
          },
          {
            queryKey: [QKEY.DOCUMENT_INFO_FIELDS.getAllValuesByDocument()[0]],
            exact: false,
          },
        ] as QueryFilters[]
        // NOTE: cancel any polling queries
        await Promise.all(
          accountGenericDependentQueries.map(query =>
            queryClient.cancelQueries(query),
          ),
        )

        // NOTE: invalidate all queries that depend on the user account
        // use [] only for generic query keys (exact=false)
        const accountDependentQueries = [
          ...accountGenericDependentQueries,
          {
            queryKey: QKEY.DOCUMENT_INFO_FIELDS.getAllLean,
          },
          {
            queryKey: QKEY.DOCUMENT_TYPES.getAll,
          },
          {
            queryKey: QKEY.USER,
          },
          {
            queryKey: QKEY.VIEWS_NAVIGATION.getFavoritesNavigation,
          },
        ] as QueryFilters[]

        await Promise.all(
          accountDependentQueries.map(query =>
            queryClient.invalidateQueries(query),
          ),
        )

        accountDependentQueries.forEach(query =>
          queryClient.removeQueries(query),
        )

        router.replace('/')
      })
    },
    onError: (error, _, context) => {
      if (context)
        queryClient.setQueryData(context.userQueryKey, context.previousUserData)
      console.error('Failed to update user account', error)
    },
  })
}

import { memo, PropsWithChildren } from 'react'

import { cn } from '@/utils'

const colorsStylesMap = {
  default:
    'bg-gray-50 dark:bg-gray-400/10 text-gray-600 dark:text-gray-400 ring-gray-500/10 dark:ring-gray-400/20',
  primary:
    'bg-primary-50 dark:bg-primary-400/10 text-primary-700 text-primary ring-primary-600/10 dark:ring-primary-400/20',
  info: 'bg-sky-50 dark:bg-sky-400/10 text-sky-700 dark:text-sky-400 ring-sky-700/10 dark:ring-sky-400/30',
  success:
    'bg-green-50 dark:bg-green-500/10 text-green-700 dark:text-green-400 ring-green-600/20 dark:ring-green-500/20',
  warning:
    'bg-yellow-50 dark:bg-yellow-400/10 text-yellow-800 dark:text-yellow-500 ring-yellow-600/20 dark:ring-yellow-400/20',
  error:
    'bg-danger-50 dark:bg-danger-400/10 text-danger-700 dark:text-danger-400 ring-danger-600/10 dark:ring-danger-400/20',
  orange:
    'bg-orange-50 dark:bg-orange-400/10 text-orange-700 dark:text-orange-400 ring-orange-600/20 dark:ring-orange-400/20',
}

export type UITagColor =
  | 'default'
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'orange'
export interface UITagProps {
  color?: UITagColor
  className?: string
  startContent?: React.ReactNode
}

const UITag = memo(
  ({
    color = 'default',
    startContent,
    children,
    className,
  }: PropsWithChildren<UITagProps>) => (
    <span
      className={cn(
        colorsStylesMap[color],
        'whitespace-nowrap',
        'inline-flex items-center select-none rounded-md px-2 py-1 text-tiny font-normal ring-1 ring-inset h-6',
        startContent && 'gap-x-1.5',
        className,
      )}
    >
      {startContent}
      {children}
    </span>
  ),
)

UITag.displayName = 'UITag'

export default UITag

import React, { useCallback, useMemo } from 'react'

import {
  ChevronDownIcon,
  UIButton,
  UICombobox,
  UIComboboxProps,
  UITag,
  Selection,
  UIIconButton,
  CancelIcon,
  UIButtonProps,
} from '@/components/ui'
import { useDocumentTypes } from '@/hooks/queries'
import { StandardTag } from '@/components/shared'
import { cn } from '@/utils'
import { DEFAULT_OTHER_DOCUMENT_TYPE } from '@/constants'

interface DocumentTypeSelectorProps {
  isDisabled: boolean
  isDense?: boolean
  placeholder?: string
  selectionMode?: UIComboboxProps['selectionMode']
  selectedKeys?: UIComboboxProps['selectedKeys']
  onChange: (keys: number[] | number) => void
  isSingleUpload?: boolean
  showClearButton?: boolean
  onClear?(): void
  triggerVariant?: UIButtonProps['variant']
  fullWidth?: boolean
}

const DocumentTypeSelector = ({
  isDisabled,
  isDense = false,
  selectionMode = 'single',
  selectedKeys,
  onChange,
  placeholder = 'Select document type ...',
  showClearButton = false,
  onClear,
  triggerVariant = 'flat',
  fullWidth = false,
}: DocumentTypeSelectorProps) => {
  const { data: documentTypes } = useDocumentTypes()

  const selectedText = useMemo(() => {
    if (!selectedKeys) return placeholder

    const parsedSelectedKeys = Array.from(selectedKeys)

    if (selectionMode === 'single') {
      return (
        documentTypes?.find(
          type => type.id.toString() === parsedSelectedKeys[0],
        )?.name || 'Unknown Document Type'
      )
    }

    if (selectionMode === 'multiple') {
      return documentTypes
        ?.filter(type => parsedSelectedKeys.includes(type.id))
        .map(type => type.name)
        .join(', ')
    }

    return placeholder
  }, [documentTypes, selectedKeys, placeholder, selectionMode])

  const availableDocumentTypes = useMemo(() => {
    if (!documentTypes) return []

    return documentTypes
      .filter(type => !type.archived)
      .map(({ name, acronym, internal, description, id }) => {
        return {
          key: id,
          ariaLabel: name,
          value: acronym,
          children: (
            <div className="flex items-center gap-x-1">
              <span className="text-small font-normal text-default-foreground">
                {name}
              </span>
              <UITag className="ml-2 h-5">{acronym}</UITag>
            </div>
          ),
          textValue: name,
          description,
          endContent: internal ? <StandardTag isCompact /> : null,
        }
      })
      .sort(
        (prev, next) =>
          prev.textValue?.localeCompare(next.textValue ?? '') ?? 0,
      )
  }, [documentTypes])

  const onSelectionChangeHandler = useCallback(
    (keys: Selection) => {
      const selectedKeys = Array.from(keys)

      onChange(
        selectionMode === 'single'
          ? Number(selectedKeys[0])
          : selectedKeys.map(key => Number(key)),
      )
    },
    [onChange, selectionMode],
  )

  return (
    <UICombobox
      disallowEmptySelection
      aria-label="Document type"
      emptyContent="No document types found"
      items={availableDocumentTypes}
      selectionMode={selectionMode}
      disabledKeys={[DEFAULT_OTHER_DOCUMENT_TYPE.id.toString()]}
      searchInputProps={{
        placeholder: 'Search by name or acronym...',
      }}
      dropdownProps={{
        placement: 'bottom-start',
        isTriggerDisabled: isDisabled,
      }}
      triggerContent={
        <UIButton
          as="div"
          disableRipple
          fullWidth={fullWidth}
          size="sm"
          variant={triggerVariant}
          endContent={
            <div className="flex items-center gap-x-1">
              {showClearButton && (
                <UIIconButton
                  variant="light"
                  size="xs"
                  icon={CancelIcon}
                  onPress={onClear}
                  iconClassName="size-3"
                />
              )}
              <ChevronDownIcon className="size-4 text-foreground-500 shrink-0" />
            </div>
          }
          className={cn(
            'text-foreground !font-normal justify-between truncate',
            isDense ? 'text-tinyPlus' : 'text-small',
            selectedText === placeholder && 'text-foreground-500',
          )}
        >
          <span className="truncate">{selectedText}</span>
        </UIButton>
      }
      onSelectionChange={onSelectionChangeHandler}
      selectedKeys={selectedKeys}
    />
  )
}

export default DocumentTypeSelector

import React, { memo } from 'react'

import { cn } from '@/utils'
import { UITooltip } from '@/components/ui'
import { ChevronDownIcon } from '@/components/ui/icons'

export interface NavSectionHeaderProps {
  isCompact: boolean
  isExpanded: boolean
  title: string
  CompactIcon: React.ElementType
  setIsExpanded(value: boolean): void
  actions?:
    | React.ReactNode
    | ((args: {
        isExpanded: boolean
        setIsExpanded: (value: boolean) => void
      }) => React.ReactNode)
  onClick?(): void
}

const NavSectionHeader = memo(
  ({
    isCompact,
    isExpanded,
    setIsExpanded,
    title,
    CompactIcon,
    actions,
    onClick,
  }: NavSectionHeaderProps) => {
    return (
      <div
        role="button"
        className={cn(
          'flex items-center justify-between font-medium transition-colors duration-200 ease-linear',
          isCompact
            ? 'relative w-11 h-11 p-0 justify-center text-default-500 hover:text-default-foreground'
            : 'ml-2 mt-2 min-h-7 w-full sidebar-nav-item-left-gutter',
        )}
        onClick={onClick}
      >
        {isCompact ? (
          <>
            <UITooltip content={title} placement="right">
              <div className="w-full h-full p-1 flex items-center justify-center">
                <CompactIcon className="h-6 w-6 text-inherit" />
              </div>
            </UITooltip>

            <ChevronDownIcon
              className={cn(
                'absolute bottom-0 -right-1 w-4 h-4 text-default-500 group-hover:text-default-foreground transition-all duration-200 ease-linear',
                !isExpanded && 'rotate-90',
              )}
            />
          </>
        ) : (
          <>
            <span className="truncate text-tiny text-foreground-500">
              {title}
            </span>
            <div className="flex gap-x-1 items-center text-xs transition-all ml-1">
              {!!actions && (
                <div className="hidden items-center h-4 group-hover:flex">
                  {typeof actions === 'function'
                    ? actions({ isExpanded, setIsExpanded })
                    : actions}
                </div>
              )}

              <ChevronDownIcon
                className={cn(
                  'w-4 h-4 text-default-500 mr-2 group-hover:text-default-foreground transition-all duration-200 ease-linear',
                  !isExpanded && 'rotate-90',
                )}
              />
            </div>
          </>
        )}
      </div>
    )
  },
)

NavSectionHeader.displayName = 'NavSectionHeader'

export default NavSectionHeader

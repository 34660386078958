import { useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'

import { QKEY } from '@/constants/queryKeys'

// TODO: Temporal solution to refresh since the old document Context for Document Editor does not match the new Stores (jotai) for the new pages
// Once all contexts are replaced, this hook can be removed
export function useRefreshCurrentDocument() {
  const queryClient = useQueryClient()

  const refresh = useCallback(
    async (documentId: string) => {
      const queryKey = QKEY.DOCUMENTS.get(documentId)
      await queryClient.invalidateQueries({ queryKey })
    },
    [queryClient],
  )

  return refresh
}

import { Button, ButtonProps } from '@heroui/button'
import React, { forwardRef } from 'react'
import { Badge, BadgeProps } from '@heroui/badge'

import { cn } from '@/utils'

import { UIIcon } from '../types'

export interface UIIconButtonProps
  extends Omit<
    ButtonProps,
    'startContent' | 'endContent' | 'isIconOnly' | 'children' | 'size'
  > {
  icon: UIIcon
  iconClassName?: string
  size?: ButtonProps['size'] | 'xs'
  badgeProps?: Omit<BadgeProps, 'children'>
}

const iconSizeMap = {
  xs: 'h-4 w-4',
  sm: 'h-[17px] w-[17px]',
  default: 'h-5 w-5',
}

const UIIconButton = forwardRef<HTMLButtonElement | null, UIIconButtonProps>(
  (
    { icon: Icon, iconClassName, className, ...props }: UIIconButtonProps,
    ref,
  ) => {
    const iconSize =
      !props.size || !iconSizeMap[props.size as keyof typeof iconSizeMap]
        ? iconSizeMap.default
        : iconSizeMap[props.size as keyof typeof iconSizeMap]

    return (
      <Button
        ref={ref}
        isIconOnly
        className={cn(
          'rounded-small',
          props.color === 'primary' &&
            props.variant === 'flat' &&
            'text-primary-700',
          props.size === 'xs' && 'min-w-6 w-6 h-6',
          className,
          'data-[focus-visible=true]:outline-0 data-[focus-visible=true]:outline-offset-0 data-[focus-visible=true]:ring-1 data-[focus-visible=true]:ring-focus data-[focus-visible=true]:ring-offset-0',
        )}
        {...props}
        size={props.size === 'xs' ? 'sm' : (props.size as ButtonProps['size'])}
      >
        {props.badgeProps ? (
          <Badge {...props.badgeProps}>
            <Icon className={cn(iconSize, iconClassName)} />
          </Badge>
        ) : (
          <Icon className={cn(iconSize, iconClassName)} />
        )}
      </Button>
    )
  },
)

UIIconButton.displayName = 'Docsum.UIIconButton'

export default UIIconButton

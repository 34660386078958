import React, { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'

import { TreeItems, UITree, TreeItem } from '@/components/ui'
import { ChartUpIcon, ComputerIcon, FilesIcon } from '@/components/ui/icons'
import { NavSection } from '@/components/shared'
import { useGetWorkspaceNavigation } from '@/hooks/queries'

import { NavSectionProps } from './types'

const initialItems: TreeItems = [
  {
    id: 'dashboard',
    label: 'Dashboard',
    type: 'item',
    href: '/dashboard',
    Icon: ChartUpIcon,
    children: [],
  },
  // TODO: Uncomment when views are implemented, should be fast with new reusable page components
  // {
  //   id: 'views',
  //   label: 'Views',
  //   type: 'item',
  //   href: '/views',
  //   Icon: LayersIcon,
  //   children: [],
  //   isDisabled: true,
  // },
]

const WorkspaceNavigation = ({ isCompact }: NavSectionProps) => {
  const pathname = usePathname()

  const { data: workspaceNavigation, isLoading: isFirstLoading } =
    useGetWorkspaceNavigation()

  const items = useMemo(() => {
    const baseItems = [...initialItems]

    if (!workspaceNavigation) {
      return baseItems
    }

    const allDocumentsItem: TreeItem = {
      id: workspaceNavigation.slug,
      href: '/all-documents',
      Icon: FilesIcon,
      label: workspaceNavigation.label,
      type: 'group',
      children: workspaceNavigation.children.length
        ? workspaceNavigation.children.map(
            view =>
              ({
                id: `views/${view.id}`,
                href: `/views/${view.id}`,
                label: view.label,
                type: 'item',
                children: [],
              }) as TreeItem,
          )
        : [],
    }

    const newNavigation = [...baseItems, allDocumentsItem]

    return newNavigation
  }, [workspaceNavigation])

  if (isFirstLoading) return null

  return (
    <NavSection
      title="Workspace"
      isCompact={isCompact}
      CompactIcon={ComputerIcon}
    >
      <UITree
        defaultItems={items}
        currentPath={pathname}
        LinkComponent={Link}
        isCompact={isCompact}
        collapsible
        indentationWidth={30}
      />
    </NavSection>
  )
}

export default WorkspaceNavigation

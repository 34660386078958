import { HighlightInit } from '@highlight-run/next/client'
import { HeroUIProvider } from '@heroui/system'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect } from 'react'

import { RootLayout } from '@/components/layout'
import { DSErrorBoundary } from '@/components/shared/ErrorBoundary'
import { API_URL } from '@/constants/api'
import { AuthContextProvider } from '@/contexts/auth/AuthContextProvider'
import { DocumentContextProvider } from '@/contexts/document/DocumentContextProvider'
import NProgressBar from '@/plugins/NProgressBar'
import { ReactQueryProvider } from '@/plugins/react-query'
import { AtomGlobalProvider, AtomGlobalSsrHydrator } from '@/services/state'
import { figtreeFont, outfitFont } from '@/plugins/local-fonts'
import { HighlightSetup } from '@/plugins/highlight'

import '@/styles/index.scss'

type ComponentWithPageLayout = AppProps & {
  Component: AppProps['Component'] & {
    PageLayout: React.ComponentType<PropsWithChildren>
  }
}

export default function App({ Component, pageProps }: ComponentWithPageLayout) {
  const router = useRouter()

  {
    /* TODO: Remove once we move to  app router work around to assign global font */
  }
  useEffect(() => {
    document.documentElement.classList.add(figtreeFont.variable)
    document.documentElement.classList.add(outfitFont.variable)
  }, [])

  return (
    <>
      <Head>
        <title>Docsum</title>
        <meta name="description" content="AI Contract Review & Negotiation" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <HeroUIProvider navigate={router.push}>
        <DSErrorBoundary>
          <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
            <AuthContextProvider>
              <DocumentContextProvider>
                <AtomGlobalProvider>
                  <AtomGlobalSsrHydrator
                    dehydratedData={pageProps.dehydratedAtomsData}
                  >
                    <RootLayout>
                      {Component.PageLayout ? (
                        <Component.PageLayout>
                          <Component {...pageProps} />
                        </Component.PageLayout>
                      ) : (
                        <Component {...pageProps} />
                      )}
                      <NProgressBar />
                    </RootLayout>
                  </AtomGlobalSsrHydrator>
                </AtomGlobalProvider>
              </DocumentContextProvider>
            </AuthContextProvider>
          </ReactQueryProvider>
        </DSErrorBoundary>
      </HeroUIProvider>

      {HighlightSetup.projectId ? (
        <HighlightInit
          projectId={HighlightSetup.projectId}
          serviceName={HighlightSetup.serviceName}
          tracingOrigins={[API_URL]}
          privacySetting="strict"
          networkRecording={{
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [],
          }}
        />
      ) : null}
    </>
  )
}

import { Link } from '@heroui/link'
import React, { memo } from 'react'

import { cn } from '@/utils'

interface SupportLinkProps {
  className?: string
}

const SupportLink = memo(({ className }: SupportLinkProps) => (
  <Link
    isExternal
    size="sm"
    className={cn('ui font-bold text-inherit', className)}
    href="mailto:support@docsum.ai"
  >
    support@docsum.ai
  </Link>
))

SupportLink.displayName = 'SupportLink'

export default SupportLink

import { DocumentFileTypeExtension } from '@/models/enums'
import { fileTypeMimes } from './file-types'

export const UPLOADER_ALLOWED_SOURCES_LIST =
  'local, url, gdrive, dropbox, onedrive'

export const UPLOADER_ALLOWED_FORMATS = [
  DocumentFileTypeExtension.PDF,
  DocumentFileTypeExtension.DOCX,
]

export const UPLOADER_OCTET_STREAM_MIME_TYPE =
  'application/octet-stream,application/octet-stream; charset=utf-8'

export const UPLOADER_ACCEPTED_MIME_TYPES = (
  types: DocumentFileTypeExtension[],
) =>
  types
    .map(
      format =>
        `${fileTypeMimes[format]},${fileTypeMimes[format]}; charset=utf-8`,
    )
    .concat(UPLOADER_OCTET_STREAM_MIME_TYPE)
    .join(',')

export const UPLOADER_MAX_UPLOAD_SIZE_MB = 30

export const UPLOADER_MAX_UPLOAD_SIZE_BYTES =
  UPLOADER_MAX_UPLOAD_SIZE_MB * 1024 * 1024

export const UPLOADER_MAX_TOTAL_UPLOAD_FILES = 100

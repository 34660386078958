import React, { memo } from 'react'

import { IconSvgProps } from '@/models/components'
import { useIsDarkTheme } from '@/hooks'

const BrandLogo = memo((props: IconSvgProps) => {
  const { isDark } = useIsDarkTheme()

  if (isDark)
    return (
      <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 130 161"
        {...props}
      >
        <path
          d="M0,91c24.865-.026,37.833-.464,46.824-7.634,2.049-1.634,3.908-3.493,5.542-5.542,7.17-8.991,7.607-21.959,7.634-46.824L0,91Z"
          fill="#72cda1"
        />
        <path
          d="M122.364,44.178c-1.634-2.049-3.493-3.908-5.542-5.542-9.288-7.406-22.816-7.629-49.322-7.636v.008c-.001,1.28-.004,2.522-.008,3.746h3.75c23.478.047,35.47.616,43.242,6.813,1.827,1.457,3.492,3.122,4.948,4.948,6.818,8.549,6.818,22.229,6.818,49.484s0,40.935-6.817,49.484c-1.457,1.827-3.122,3.492-4.948,4.948-8.549,6.818-22.229,6.818-49.484,6.818s-40.935,0-49.484-6.817c-1.827-1.457-3.492-3.122-4.948-4.948-6.197-7.772-6.767-19.764-6.814-43.242v-3.75c-1.223.004-2.466.007-3.746.008h-.008c.007,26.506.229,40.035,7.636,49.322,1.634,2.049,3.493,3.908,5.542,5.542,9.575,7.636,23.658,7.636,51.822,7.636s42.247,0,51.822-7.636c2.049-1.634,3.908-3.493,5.542-5.542,7.636-9.575,7.636-23.658,7.636-51.822s0-42.247-7.636-51.822Z"
          fill="#72cda1"
        />
        <path
          d="M90.599,0v58.693c-2.769-3.195-6.06-5.868-9.9-7.992-4.028-2.226-8.485-3.646-13.354-4.291-.215,8.077-.732,14.723-1.977,20.407,5.226.084,9.806,1.305,13.731,3.684,4.066,2.468,7.232,5.899,9.5,10.3,2.266,4.399,3.399,9.468,3.399,15.2s-1.134,10.799-3.399,15.2c-2.269,4.4-5.434,7.834-9.5,10.299-4.068,2.469-8.833,3.7-14.3,3.7-5.333,0-10.034-1.23-14.1-3.7-4.069-2.465-7.268-5.899-9.6-10.299-2.334-4.4-3.5-9.466-3.5-15.2,0-.02.001-.039.001-.059-6.148,1.575-13.441,2.18-22.542,2.412.292,8.479,2.289,16.201,6.04,23.146,4.066,7.534,9.6,13.5,16.6,17.9,7,4.4,14.832,6.6,23.501,6.6,7.331,0,13.865-1.569,19.6-4.7,3.817-2.086,7.075-4.702,9.799-7.823v10.523h22V0h-22Z"
          fill="#fafaf7"
        />
      </svg>
    )

  return (
    <svg
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 130 161"
      {...props}
    >
      <path
        d="M0,91c24.865-.026,37.833-.464,46.824-7.634,2.049-1.634,3.908-3.493,5.542-5.542,7.17-8.991,7.607-21.959,7.634-46.824L0,91Z"
        fill="#72cda1"
      />
      <path
        d="M122.364,44.178c-1.634-2.049-3.493-3.908-5.542-5.542-9.288-7.406-22.816-7.629-49.322-7.636v.008c-.028,26.232-.453,40.437-9.271,51.493-1.981,2.484-4.245,4.748-6.729,6.729C40.444,98.047,26.24,98.472.008,98.5h-.008c.007,26.506.229,40.035,7.636,49.322,1.634,2.049,3.493,3.908,5.542,5.542,9.575,7.636,23.658,7.636,51.822,7.636s42.247,0,51.822-7.636c2.049-1.634,3.908-3.493,5.542-5.542,7.636-9.575,7.636-23.658,7.636-51.822s0-42.247-7.636-51.822Z"
        fill="#d3f1d0"
      />
      <path
        d="M90.599,0v58.693c-2.769-3.195-6.06-5.868-9.9-7.992-4.028-2.226-8.485-3.646-13.354-4.291-.215,8.077-.732,14.723-1.977,20.407,5.226.084,9.806,1.305,13.731,3.684,4.066,2.468,7.232,5.899,9.5,10.3,2.266,4.399,3.399,9.468,3.399,15.2s-1.134,10.799-3.399,15.2c-2.269,4.4-5.434,7.834-9.5,10.299-4.068,2.469-8.833,3.7-14.3,3.7-5.333,0-10.034-1.23-14.1-3.7-4.069-2.465-7.268-5.899-9.6-10.299-2.334-4.4-3.5-9.466-3.5-15.2,0-.02.001-.039.001-.059-6.148,1.575-13.441,2.18-22.542,2.412.292,8.479,2.289,16.201,6.04,23.146,4.066,7.534,9.6,13.5,16.6,17.9,7,4.4,14.832,6.6,23.501,6.6,7.331,0,13.865-1.569,19.6-4.7,3.817-2.086,7.075-4.702,9.799-7.823v10.523h22V0h-22Z"
        fill="#183a28"
      />
    </svg>
  )
})

BrandLogo.displayName = 'BrandLogo'

export default BrandLogo

import React, { useCallback, useEffect, useState } from 'react'

import { UIButton, FileAddIcon, UITooltip } from '@/components/ui'
import { KeyboardShortcuts } from '@/models/enums'

import UploadFilesDialog from './UploadFilesDialog'

interface DocumentsAddButtonProps {
  text?: string
  asAction?: boolean
  isCompact?: boolean
}

const DocumentsAddButton = ({
  text = 'Upload Document',
  asAction = false,
  isCompact = false,
}: DocumentsAddButtonProps) => {
  const [addDocOpen, setAddDocOpen] = useState(false)

  const onCloseHandler = useCallback(() => setAddDocOpen(false), [])

  const openDialogHandler = useCallback(() => setAddDocOpen(true), [])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      const isBodyFocused = document.activeElement === document.body
      if (
        typeof e.key === 'string' &&
        e.key.toLowerCase() === KeyboardShortcuts.UPLOAD_DOCUMENTS &&
        isBodyFocused &&
        !addDocOpen
      ) {
        openDialogHandler()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [openDialogHandler, addDocOpen])

  return (
    <>
      {asAction ? (
        <UIButton
          color="primary"
          size="md"
          variant="light"
          onPress={openDialogHandler}
          startContent={
            <FileAddIcon className="flex-none text-current h-5 w-5" />
          }
        >
          {text}
        </UIButton>
      ) : isCompact ? (
        <UITooltip
          content="Upload new document"
          shortcutText="D"
          placement="right"
        >
          <UIButton
            isIconOnly
            color="primary"
            variant="shadow"
            size="sm"
            onPress={openDialogHandler}
            className="rounded-full"
            aria-label="Upload new documents"
          >
            <FileAddIcon className="flex-none text-current h-5 w-5" />
          </UIButton>
        </UITooltip>
      ) : (
        <div className="flex justify-center">
          <UITooltip
            content="Upload new document"
            shortcutText="D"
            placement="right"
          >
            <UIButton
              color="primary"
              variant="shadow"
              size="sm"
              className="w-9/12"
              onPress={openDialogHandler}
              aria-label="Upload new documents"
              startContent={
                <FileAddIcon className="flex-none text-current h-[18px] w-[18px]" />
              }
            >
              {text}
            </UIButton>
          </UITooltip>
        </div>
      )}

      <UploadFilesDialog
        canSelectDocumentType
        isSingleUpload={false}
        title="Upload Documents"
        description="Your contract data is secure and confidential"
        onChange={setAddDocOpen}
        isOpen={addDocOpen}
        onClose={onCloseHandler}
      />
    </>
  )
}

export default DocumentsAddButton

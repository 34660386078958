import {
  format,
  formatDistanceToNow,
  formatRelative,
  isValid,
  parseISO,
} from 'date-fns'

export const getDateFromStr = (dateStr: string | null) => {
  return dateStr ? new Date(dateStr) : null
}

export const isValidISOString = (input: string) => isValid(parseISO(input))

export const formatISO = (date: string | Date, dateFormat: string) =>
  date instanceof Date
    ? format(date, dateFormat)
    : format(parseISO(date), dateFormat)

export const formatISOToLongDateTime = (date: string) => formatISO(date, 'PPpp')

export const formatISOToShortDateTime = (date: string) => formatISO(date, 'Pp')

export const formatISOToLongDate = (date: string) => formatISO(date, 'PP')

export const formatISOToShortDate = (date: string) => formatISO(date, 'Pp')

export const formatISOToFullHumanReadableDate = (date: string) =>
  formatISO(date, 'MMMM d, yyyy')

export const formatISOToRelativeToNow = (
  isoDate: string,
  options: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
  } = { addSuffix: true },
) => formatRelative(parseISO(isoDate), new Date(), options)

export const formatISODistanceToNow = (
  isoDate: string,
  options: {
    includeSeconds?: boolean
    addSuffix?: boolean
    locale?: Locale
  } = { addSuffix: true },
) => formatDistanceToNow(parseISO(isoDate), options)

export const formatISOToHumanReadableDate = (
  date: string,
  { includeYear }: { includeYear: boolean } = { includeYear: true },
) => formatISO(date, `MMM d${includeYear ? ', yyyy' : ''}`)

export const getCurrentNamedTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

import React, { memo, useCallback } from 'react'
import Link from 'next/link'

import {
  CheckmarkCircleIcon,
  FilePreviewIcon,
  HashIcon,
  useToast,
} from '@/components/ui'
import {
  useRefreshCurrentDocument,
  useStreamEventsState,
  useSubscribeStreamEvents,
} from '@/hooks/'
import { EventStreamUploadDocumentVersionPayload } from '@/models/api'

const TEN_SECONDS_DELAY = 10000

const NewDocumentVersionReadyNotification = memo(() => {
  const { toast, isToastVisible } = useToast()

  //TODO: remove once old contexts are removed
  const refreshCurrentDocument = useRefreshCurrentDocument()

  const {
    eventStreamMessageId,
    currentLoadingToastId,
    setEventStreamMessageId,
    setCurrentLoadingToastId,
  } = useStreamEventsState()

  const onAutoCloseHandler = useCallback(() => {
    setCurrentLoadingToastId(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useSubscribeStreamEvents({
    messageId: eventStreamMessageId,
    onMessage: payload => {
      const { executeDocumentStorage } =
        payload as EventStreamUploadDocumentVersionPayload

      if (!executeDocumentStorage) return

      const { documentId, documentVersionNumber, documentName } =
        executeDocumentStorage

      const toastId = toast.message('New document version available', {
        id:
          !!currentLoadingToastId && isToastVisible(currentLoadingToastId)
            ? currentLoadingToastId
            : undefined,
        duration: TEN_SECONDS_DELAY,
        icon: <CheckmarkCircleIcon />,
        onDismiss: onAutoCloseHandler,
        onAutoClose: onAutoCloseHandler,
        description: (
          <div className="flex flex-col gap-1 pt-1">
            <div className="flex items-start gap-2">
              <FilePreviewIcon className="size-3 text-default-500 mt-0.5 shrink-0" />
              <span className="font-bold text-tiny">{documentName}</span>
            </div>
            <div className="flex items-start gap-2">
              <HashIcon className="size-3 text-default-500 mt-0.5 shrink-0" />
              <span className="font-bold text-tiny">
                v{documentVersionNumber}
              </span>
            </div>
            <div className="pt-1">
              <Link
                href={`/document/${documentId}/${documentVersionNumber}`}
                onClick={() => toast.dismiss(toastId)}
                className="text-tiny text-primary hover:opacity-80 active:opacity-disabled transition-opacity outline-none"
              >
                View document version
              </Link>
            </div>
          </div>
        ),
      })
      setEventStreamMessageId(undefined)
      refreshCurrentDocument(documentId)
    },
  })

  return null
})

NewDocumentVersionReadyNotification.displayName =
  'DocumentEditor.NewDocumentVersionReadyNotification'

export default NewDocumentVersionReadyNotification

import { useEffect, useState } from 'react'

import { useAuthContext } from '@/hooks'
import { streamEventsApi } from '@/services/api'
import { EventStreamMessage, EventStreamPayload } from '@/models/api'

interface UseSubscribeStreamEventsProps {
  messageId: string | undefined
  onMessage: (payload: EventStreamPayload) => void
}

const EVENT_RESULT_TYPE = 'result'

export function useSubscribeStreamEvents({
  messageId,
  onMessage,
}: UseSubscribeStreamEventsProps) {
  const [openRequest, setOpenRequest] = useState<string | undefined>(messageId)

  const { getCurrentToken } = useAuthContext()

  useEffect(() => {
    setOpenRequest(messageId)
  }, [messageId])

  useEffect(() => {
    if (!openRequest) return

    const token = getCurrentToken()
    if (!token) return

    const sse = streamEventsApi.getEventSourceStream(openRequest, token)

    function getMessageStream(data: EventStreamMessage) {
      if (data === null) return

      if (data.payload) {
        if (data.type === EVENT_RESULT_TYPE) {
          onMessage(data.payload)
          setOpenRequest(undefined)
        }
      }
    }

    sse.onmessage = e => {
      let data

      try {
        data = JSON.parse(e.data)
      } catch (error) {
        console.error('Failed to parse SSE message:', {
          error,
          data: e.data,
        })
        return
      }

      getMessageStream(data)
    }

    sse.onerror = () => {
      setOpenRequest(undefined)
      sse.close()
    }

    return () => {
      setOpenRequest(undefined)
      sse.close()
    }
    // NOTE: Should only depend on openRequest because we want to close the sse connection ONLY when the component unmounts and not if any other dependency changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openRequest])

  return {
    setOpenRequest,
  }
}

import { ScrollShadow } from '@heroui/scroll-shadow'
import { Spacer } from '@heroui/spacer'

import { useSidebar } from '@/hooks'
import { cn } from '@/utils'
import { DocumentsAddButton } from '@/components/documents'

import { SidebarAccountSwitcher } from './AccountSwitcher'
import { SidebarBrand } from './Brand'
import { SidebarCollapseButton } from './CollapseButton'
import { SidebarUser } from './User'
import {
  WorkspaceNavigation,
  FavoritesNavigation,
  NavSectionDivider,
  ConfigureNavigation,
} from './nav-sections'

const Sidebar = () => {
  const { isCompact, isHidden, canHide, setIsSidebarHidden } = useSidebar()

  return (
    <>
      {canHide && !isHidden && (
        <div
          className={cn(
            'fixed inset-0 bg-black/10 dark:bg-gray-900/20 z-40 transition-opacity ease-linear',
            isHidden ? 'opacity-0' : 'opacity-100',
          )}
          onClick={() => setIsSidebarHidden(true)}
        />
      )}
      <div
        className={cn(
          'flex h-screen min-h-0 w-80 max-w-[330px] flex-col py-5 transition-all ease-in-out',
          {
            'w-16 items-center px-2 py-5': isCompact,
            'absolute top-0 left-0 z-50 border-r border-divider/15 dark:border-divider/10 shadow-sidebarLight dark:shadow-sidebarDark bg-gray-100 dark:bg-black':
              canHide,
            relative: !canHide,
            '-ml-72 -translate-x-72': isHidden,
          },
        )}
      >
        <div
          className={cn(
            'flex items-center justify-between',
            !isCompact && 'sidebar-nav-item-gutter',
          )}
        >
          <SidebarBrand isCompact={isCompact} />

          {!isCompact && <SidebarCollapseButton />}
        </div>

        <div className="pt-7">
          <DocumentsAddButton isCompact={isCompact} />
        </div>

        <ScrollShadow
          className={cn(
            'h-full max-h-full pb-3 mt-4 overflow-x-hidden',
            !isCompact && 'sidebar-nav-item-right-gutter pl-2',
          )}
        >
          {isCompact && <NavSectionDivider />}

          <WorkspaceNavigation isCompact={isCompact} />

          {isCompact && <NavSectionDivider />}

          <FavoritesNavigation isCompact={isCompact} />

          {isCompact && <NavSectionDivider />}

          <ConfigureNavigation isCompact={isCompact} />
        </ScrollShadow>

        <Spacer y={2} />

        <footer
          className={cn(
            'mt-auto flex flex-col',
            isCompact ? 'items-center' : 'sidebar-nav-item-gutter mx-1',
          )}
        >
          <SidebarAccountSwitcher isCompact={isCompact} />

          <Spacer y={6} />

          <SidebarUser isCompact={isCompact} />
        </footer>
      </div>
    </>
  )
}

Sidebar.displayName = 'Sidebar'

export default Sidebar

import {
  DocumentExportCsvRequest,
  DocumentFileBatchUploadRequest,
  DocumentFileBatchUploadResponse,
  DocumentLean,
  DocumentPatchRequest,
  DocumentRunInfoExtractionRequest,
} from '@/models/api'
import { API_DOCUMENTS_ROUTE_PATH } from '@/constants/api'

import BaseApi from './base'
import { apiClient, ApiRequestConfig, getHeaders } from './client'

export class DocumentsApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = API_DOCUMENTS_ROUTE_PATH
  }

  async getById(
    documentId: string,
    { signal, ...configProps }: ApiRequestConfig = {},
  ) {
    return apiClient.get<DocumentLean>(
      this.resourcePath,
      `${documentId}/lean`,
      {
        signal,
        ...getHeaders(configProps),
      },
    )
  }

  async uploadDocumentFiles(payload: DocumentFileBatchUploadRequest) {
    return apiClient.post<DocumentFileBatchUploadResponse>(
      `${this.resourcePath}/upload/batch`,
      payload,
    )
  }

  async runInfoExtraction({
    documentId,
    fields,
  }: DocumentRunInfoExtractionRequest) {
    return apiClient.post(
      `${this.resourcePath}/${documentId}/extract-info`,
      fields,
    )
  }

  async patchDocument({ documentId, ...changes }: DocumentPatchRequest) {
    return apiClient.patch(this.resourcePath, documentId, changes)
  }

  async exportToCsv({ params, payload }: DocumentExportCsvRequest) {
    return apiClient.post(`${this.resourcePath}/export/csv`, payload, {
      params,
      responseType: 'blob',
    })
  }
}

const documentsApi = new DocumentsApi()

export default documentsApi

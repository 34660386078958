import {
  QueryFilters,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'

import { QKEY } from '@/constants/queryKeys'
import { DocumentFileBatchUploadRequest } from '@/models/api'
import { documentsApi } from '@/services/api'

export function useUploadDocumentFiles() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: QKEY.DOCUMENTS.batchUpload,
    mutationFn: (params: DocumentFileBatchUploadRequest) =>
      documentsApi.uploadDocumentFiles(params),
    onSuccess: async _data => {
      const genericKey = [
        QKEY.DOCUMENT_INFO_FIELDS.getAllValuesByDocument()[0],
      ] as const
      const filters: QueryFilters = {
        queryKey: genericKey,
        exact: false,
      }

      queryClient.cancelQueries(filters)
      await queryClient.invalidateQueries(filters)
    },
  })
}

import { memo } from 'react'

import { SidebarChildrenProps } from '@/models/components'
import { cn } from '@/utils'

import Logo from './Logo'
import LogoFull from './LogoFull'

export const SidebarBrand = memo(({ isCompact }: SidebarChildrenProps) => {
  const BrandLogo = !isCompact ? LogoFull : Logo

  return (
    <div
      className={cn('flex items-center gap-3 pl-2', {
        'justify-center gap-0 p-0': isCompact,
      })}
    >
      <div className="flex h-8 w-auto items-center justify-center rounded-full bg-transparent">
        <BrandLogo
          className={isCompact ? 'size-[30px]' : 'h-[30px] w-[128px]'}
        />
      </div>
    </div>
  )
})

SidebarBrand.displayName = 'SidebarBrand'

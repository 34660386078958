import { FiltersGetAllSupportedResponse } from '@/models/api'

import BaseApi from './base'
import { apiClient, getHeaders, type ApiRequestConfig } from './client'

export class FiltersApi extends BaseApi {
  constructor() {
    super()
    this.resourcePath = 'filters'
  }

  async getSupportedFilters({ signal, ...configProps }: ApiRequestConfig = {}) {
    return apiClient.getAll<FiltersGetAllSupportedResponse>(this.resourcePath, {
      signal,
      ...getHeaders(configProps),
    })
  }
}

const filtersApi = new FiltersApi()

export default filtersApi

import { useMemo, useState, useId, useCallback } from 'react'
import { Input, InputProps } from '@heroui/input'
import { SearchIcon } from '@heroui/shared-icons'

import { cn } from '@/utils'

import {
  UIDropdown,
  UIDropdownProps,
  UIItemConfig,
  UISectionConfig,
} from '../Dropdown'

export interface UIComboboxProps<K extends object = { key: string }>
  extends UIDropdownProps<K> {
  disableSearch?: boolean
  searchInputProps?: InputProps
}

const UICombobox = <K extends UIItemConfig<K>>({
  searchInputProps,
  items,
  topContent,
  disableSearch = false,
  sections,
  ...props
}: UIComboboxProps<K>) => {
  const id = useId()

  const [search, setSearch] = useState('')

  const filteredItems = useMemo(() => {
    if (!items) return undefined

    if (!search) return items

    return items.filter(item => {
      return (
        item.textValue?.toLowerCase().includes(search.toLowerCase()) ||
        item.value?.toString().toLowerCase().includes(search.toLowerCase())
      )
    }) as Iterable<K> & UIItemConfig<K>[]
  }, [items, search])

  const clearSearch = useCallback(() => setSearch(''), [])

  const onEscapeHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape') {
        clearSearch()
      }
    },
    [clearSearch],
  )

  const filteredSections = useMemo<
    UISectionConfig<UIItemConfig<K>>[] | undefined
  >(() => {
    if (!sections) return undefined

    if (!search) return sections

    return sections
      .map(section => ({
        ...section,
        items: section.items.filter(
          item =>
            item.textValue?.toLowerCase().includes(search.toLowerCase()) ||
            item.value?.toString().toLowerCase().includes(search.toLowerCase()),
        ),
      }))
      .filter(section => section.items.length > 0) as UISectionConfig<
      UIItemConfig<K>
    >[]
  }, [sections, search])

  const onInputKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Escape' && 'continuePropagation' in e) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        e.continuePropagation()
        clearSearch()
        return
      }

      if (e.key === 'ArrowDown' && filteredItems?.length) {
        e.preventDefault()

        const dropdownElement = document.getElementById(id)
        const listElement =
          dropdownElement?.querySelector(`ul[data-slot="list"]`)
        const firstItemElement = listElement?.children[0] as HTMLInputElement

        if (!firstItemElement) return

        firstItemElement?.setAttribute('data-focus-visible', 'true')
        firstItemElement?.focus()
      }
    },
    [filteredItems, id, clearSearch],
  )

  return (
    <UIDropdown
      {...props}
      id={id}
      items={filteredItems}
      sections={filteredSections as UISectionConfig<K>[]}
      classNames={{
        emptyContent: 'text-xs flex justify-center pt-2',
        ...props.classNames,
        list: cn('max-h-72 overflow-y-auto', props.classNames?.list),
      }}
      dropdownProps={{
        ...props.dropdownProps,
        type: 'listbox',
        onKeyDown: !disableSearch ? onEscapeHandler : undefined,
      }}
      onClose={!disableSearch ? clearSearch : undefined}
      emptyContent={props.emptyContent ?? 'No matching results'}
      topContent={
        <>
          {topContent}

          {!disableSearch && (
            <div className="pb-1">
              <Input
                size="sm"
                className="ui z-10"
                classNames={{
                  input: 'text-xs',
                }}
                placeholder="Search..."
                {...searchInputProps}
                isClearable
                startContent={<SearchIcon className="h-4 w-4" />}
                value={search}
                onValueChange={setSearch}
                onKeyDown={onInputKeyDownHandler}
              />
            </div>
          )}
        </>
      }
    />
  )
}

UICombobox.displayName = 'UIDropdown'

export default UICombobox
